import React, { lazy, Suspense } from 'react'
import { Router, Switch, Route, RouteComponentProps } from 'react-router-dom'

import history from 'utils/history'
import { Routes } from 'constants/routes'

import { PublicRoute, PrivateRouteLayoutSwitch } from 'containers/Router'
import DashboardLayout from 'containers/DashboardLayout'
import Spinner from 'components/Spinner'

// Public Pages
const Login = lazy(() => import('./Login'))
const Register = lazy(() => import('./Register'))
const ForgotPassword = lazy(() => import('./ForgotPassword'))

// Protected Pages
const Home = lazy(() => import('./Home'))
const Profile = lazy(() => import('./Profile'))
const SetPassword = lazy(() => import('./SetPassword'))
const Applications = lazy(() => import('./Applications'))
const Search = lazy(() => import('./Search'))
const Reports = lazy(() => import('./Reports'))
const Administrators = lazy(() => import('./Administrators'))
const AdminForm = lazy(() => import('./Administrators/AdminForm'))
const LeadsWelcome = lazy(() => import('./Leads/WelcomePage'))
const LeadsContent = lazy(() => import('./Leads/ContentPage'))
const Admissions = lazy(() => import('./Admissions'))
const Risk = lazy(() => import('./Risk'))
const PartnerProfile = lazy(() => import('./PartnerProfile'))
const Metrics = lazy(() => import('./Metrics'))
const Integrations = lazy(() => import('./Integrations'))
const Digest = lazy(() => import('./Digest'))
const Lite = lazy(() => import('./Lite'))
const LiteLandingPage = lazy(() => import('./LiteLandingPreview'))
const Permissions = lazy(() => import('./Permissions'))

type Props = {
  children?: React.ReactNode
}

const EmptyRender: React.FC<RouteComponentProps> = (
  props: RouteComponentProps & Props
) => {
  return <>{props.children}</>
}

const Pages = () => {
  return (
    <Suspense fallback={<Spinner fullscreen size="xl" />}>
      <Router history={history}>
        <Switch>
          <PublicRoute path={Routes.login} component={Login} />
          <PublicRoute path={Routes.register} component={Register} />
          <PublicRoute
            path={Routes.forgotPassword}
            component={ForgotPassword}
          />
          <PublicRoute path={Routes.setPassword} component={SetPassword} />

          <PrivateRouteLayoutSwitch
            path={Routes.index}
            layoutComponent={
              history.location.pathname === Routes.liteLandingPreview
                ? EmptyRender
                : DashboardLayout
            }
          >
            <Route exact path={Routes.index} component={Home} />
            <Route exact path={Routes.profile} component={Profile} />
            <Route exact path={Routes.applications} component={Applications} />
            <Route exact path={Routes.search} component={Search} />
            <Route exact path={Routes.reports} component={Reports} />
            <Route exact path={Routes.admins} component={Administrators} />
            <Route exact path={Routes.adminForm} component={AdminForm} />
            <Route exact path={Routes.leadsWelcome} component={LeadsWelcome} />
            <Route exact path={Routes.leadsContent} component={LeadsContent} />
            <Route exact path={Routes.admissions} component={Admissions} />
            <Route exact path={Routes.risk} component={Risk} />
            <Route
              exact
              path={Routes.partnerProfile}
              component={PartnerProfile}
            />
            <Route exact path={Routes.metrics} component={Metrics} />
            <Route exact path={Routes.integrations} component={Integrations} />
            <Route exact path={Routes.digestValidator} component={Digest} />
            <Route exact path={Routes.lite} component={Lite} />
            <Route
              exact
              path={Routes.liteLandingPreview}
              component={LiteLandingPage}
            />
            <Route exact path={Routes.permissions} component={Permissions} />
          </PrivateRouteLayoutSwitch>
        </Switch>
      </Router>
    </Suspense>
  )
}

export default Pages
