export enum Labels {
  firstName = 'inputs.firstName',
  lastName = 'inputs.lastName',
  role = 'inputs.role',
  campus = 'inputs.campus',
  allSchool = 'inputs.allSchool',
  disbursementNotification = 'inputs.disbursementNotification',
  POCLabel = 'inputs.POCLabel',
  access = 'inputs.access',
  email = 'inputs.email',
  password = 'inputs.password',
  label = 'inputs.label',
  type = 'inputs.type',
  contactNumber = 'inputs.contactNumber',
  partnerName = 'inputs.partnerName',
  province = 'inputs.province',
  city = 'inputs.city',
  streetAddress = 'inputs.streetAddress',
}

export enum Errors {
  required = 'inputs.errors.required',
  invalidCredentials = 'inputs.errors.invalidCredentials',
  requestThrottle = 'inputs.errors.requestThrottle',
  multipleExistingUser = 'errors.multipleExistingUser',
  wrongEmailFormat = 'errors.wrongEmailFormat',
  duplicateContactNumber = 'errors.duplicateContactNumber',
  duplicateContactEmail = 'errors.duplicateContactEmail',
  multipleExistingEmail = 'errors.multipleExistingEmail',
  multipleExistingEmailInPostDraft = 'errors.multipleExistingEmailInPostDraft',
  invalidURL = 'errors.invalidURL',
  phoneNumberTooShort = 'errors.phoneNumberTooShort',
}

export enum Messages {
  selectDate = 'inputs.messages.selectDate',
  startDate = 'inputs.messages.startDate',
  endDate = 'inputs.messages.endDate',
}
