import React, { useState, useEffect } from 'react'
import { useIdleTimer } from 'react-idle-timer'

import useAuth from 'hooks/useAuth'
import useTranslation from 'hooks/useTranslation'

import Modal from 'components/Modal'
import Button from 'components/Button'
import { Text } from 'components/Typography'
import Icon from 'components/Icon'
import session, { SessionStorageKey } from 'utils/sessionStorage'

import styles from './SessionTimeoutModal.module.scss'

const COUNTDOWN = 120

const SessionTimeoutModal = () => {
  // 30 minute -> millisecond
  const timeout = 1000 * 60 * 30
  const [isIdle, setIsIdle] = useState(false)
  const [countdown, setCountdown] = useState(COUNTDOWN)

  const { isAuth, logout } = useAuth()
  const { t, copy } = useTranslation()

  const handleOnActive = () => setIsIdle(false)
  const handleOnIdle = () => setIsIdle(true)

  const { reset } = useIdleTimer({
    timeout,
    stopOnIdle: true,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
  })

  useEffect(() => {
    if (!isAuth) {
      reset()
      setIsIdle(false)
    }
    const timer = setTimeout(() => {
      if (isIdle && isAuth && countdown) setCountdown((c) => c - 1)
      if (countdown === 0) {
        session.setItem(SessionStorageKey.sessionTimedOut, 'true')
        reset()
        setIsIdle(false)
        setCountdown(COUNTDOWN)
        logout()
      }
    }, 1000)
    return () => {
      clearTimeout(timer)
    }
  }, [isIdle, isAuth, logout, countdown, reset])

  const secondsToTime = (secs: number) => {
    const divisorMinutes = secs % (60 * 60)
    const divisorSeconds = divisorMinutes % 60
    const minutes = Math.floor(divisorMinutes / 60)
    const seconds = Math.ceil(divisorSeconds)
    const obj = {
      min: minutes,
      secs: seconds,
    }
    return obj
  }

  return (
    <>
      {isIdle && isAuth && (
        <Modal visible={isIdle} className={styles.sessionTimeoutModal}>
          <div className={styles.icon}>
            <Icon name="alarm-clock" size="lg" type="danger" />
          </div>
          <div className={styles.content}>
            <Text
              className={styles.heading}
              size="lg"
              block
              type="black"
              strong
            >
              {t(copy.Components.SessionTimeout.heading)}
            </Text>
            <Text block paragraph size="sm">
              {t(copy.Components.SessionTimeout.infoCountdown, {
                min: secondsToTime(countdown).min,
                secs: secondsToTime(countdown).secs,
              })}
            </Text>
            <div className={styles.actions}>
              <Button
                htmlType="submit"
                block
                type="light"
                onClick={() => {
                  logout()
                  reset()
                  setIsIdle(false)
                  setCountdown(COUNTDOWN)
                }}
              >
                {t(copy.Components.Sidebar.logout)}
              </Button>
              <Button
                htmlType="submit"
                block
                onClick={() => {
                  reset()
                  setIsIdle(false)
                  setCountdown(COUNTDOWN)
                }}
              >
                {t(copy.Components.SessionTimeout.staySignedIn)}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

export default SessionTimeoutModal
