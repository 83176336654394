import { Buttons, Links } from 'constants/copy/touchables'

export const BUTTONS_COPY: CopyRecord<Buttons> = {
  [Buttons.login]: 'Masuk',
  [Buttons.register]: 'Selesaikan pendaftaran',
  [Buttons.resetPassword]: 'Pulihkan akun kamu',
  [Buttons.setPassword]: 'Atur kata sandi',
  [Buttons.send]: 'Kirim',
  [Buttons.back]: 'Kembali',
  [Buttons.next]: 'Lanjutkan',
  [Buttons.delete]: 'Hapus',
  [Buttons.add]: 'Add',
  [Buttons.edit]: 'Edit',
  [Buttons.reportReceived]: 'Laporan Diterima',
  [Buttons.viewApplicant]: 'Lihat aplikasi',
  [Buttons.sendNotification]: 'Kirim Notifikasi',
  [Buttons.downloadReport]: 'Unduh Laporan',
  [Buttons.downloadAndSendNotification]: 'Unduh & Kirim Notifikasi',
  [Buttons.viewAll]: 'Lihat semua',
  [Buttons.export]: 'Unduh',
  [Buttons.exportReport]: 'Unduh Report',
  [Buttons.exportAsPDF]: 'Export ke PDF',
  [Buttons.save]: 'Simpan',
  [Buttons.editBasicInfo]: 'Ubah informasi dasar',
  [Buttons.remove]: 'Hilangkan',
  [Buttons.cancel]: 'Batal',
  [Buttons.preview]: 'Preview',
  [Buttons.submit]: 'Submit',
  [Buttons.addLead]: 'Tambah Lead',
  [Buttons.submitLead]: 'Submit Lead',
  [Buttons.viewDraft]: 'Lihat Draft',
  [Buttons.saveDraft]: 'Simpan di Draft',
  [Buttons.exitAndSaveDraft]: 'Keluar & simpan di Draft',
  [Buttons.skip]: 'Lewati',
  [Buttons.skipModal]: 'Skip',
  [Buttons.additionalInfo]: 'Tambahan Informasi',
  [Buttons.sendSMS]: 'Kirim SMS',
  [Buttons.addAddress]: 'Add Address',
  [Buttons.addContactNumber]: 'Add Contact Number',
  [Buttons.updatePopulation]: 'Perbarui Jumlah Pelajar',
  [Buttons.addEmail]: 'Tambah Kontak',
  [Buttons.addMainProgram]: 'Program Studi Sarjana',
  [Buttons.batchUpload]: 'Unggah Batch',
  [Buttons.addGraduateProgram]: 'Program Studi Pasca Sarjana',
  [Buttons.addShortCourse]: 'Program Kursus',
  [Buttons.addFile]: 'Tambah File',
  [Buttons.otherFee]: 'Jawab Survey',
  [Buttons.internalFinancingProgram]: 'Program Pendanaan Internal',
  [Buttons.addDeadline]: 'Jadwal Pembayaran',
  [Buttons.details]: 'Details',
  [Buttons.goBackToDashboard]: 'Go Back To Dashboard',
  [Buttons.answerSurvey]: 'Answer Survey',
  [Buttons.saveInternalFinancingProgram]: 'Simpan Program Pendanaan Internal',
  [Buttons.savePaymentDeadline]: 'Simpan Jadwal Pembayaran',
  [Buttons.VAupdate]: 'Virtual Account Pelajar',
  [Buttons.scrollDown]: 'Scroll Down',
  [Buttons.applyFilters]: 'Terapkan Filter',
  [Buttons.reset]: 'Atur ulang',
  [Buttons.downloadErrorFile]: 'Unduh File',
  [Buttons.EN]: 'EN',
  [Buttons.ID]: 'ID',
  [Buttons.integrationsBasic]: 'Dasar',
  [Buttons.integrationsWholesale]: 'Grosir',
  [Buttons.integrationsDocumentation]: 'Akses Dokumentasi Kami',
  [Buttons.downloadAPI]: 'Download API Key',
  [Buttons.previewDocument]: 'Lihat Document',
  [Buttons.downloadDocument]: 'Download Document',
  [Buttons.download]: 'Download',
  [Buttons.inviteNewAdmin]: 'Undang Admin',
  [Buttons.discard]: 'Batal',
  [Buttons.yes]: 'Ya',
}

export const LINKS_COPY: CopyRecord<Links> = {
  [Links.forgotPassword]: 'Lupa kata sandi?',
  [Links.getStarted]: 'Mulai',
  [Links.edit]: 'Ubah',
}
