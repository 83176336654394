import { COUNTRY } from 'constants/config'

import appIconImg from './app_icon.png'

const getAppIcon = (country: Country) => {
  switch (country) {
    case 'PH':
      return appIconImg
    case 'ID':
      return appIconImg
  }
}

const appIcon = getAppIcon(COUNTRY)

export default appIcon
