import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/remote-config'

import { IS_DEV } from 'constants/config'

import {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
} from '../../env'

const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
  measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
}

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig)
  firebase.analytics()
  const remoteConfig = firebase.remoteConfig()
  remoteConfig.defaultConfig = {
    leads_management: 'enabled',
    risk_module: 'enabled',
    profile_settings_module: 'enabled',
  }
  if (IS_DEV) {
    remoteConfig.settings.minimumFetchIntervalMillis = 10000
  } else {
    remoteConfig.settings.minimumFetchIntervalMillis = 3600000
  }
}
