import React from 'react'
import { useHistory } from 'react-router-dom'

import { useCurrentUser } from 'services/me'
import useQuery from 'hooks/useQuery'
import useTranslation from 'hooks/useTranslation'
import { DashboardQuery } from 'types/query'

import Icon, { IconName } from 'components/Icon'
import Modal from 'components/Modal'
import Spinner from 'components/Spinner'
import { Text } from 'components/Typography'

import styles from './ViewProfile.module.scss'

const ViewProfile = () => {
  const history = useHistory()
  const { t, copy } = useTranslation()
  const { data: user } = useCurrentUser()

  const { [DashboardQuery.viewProfile]: query } = useQuery()
  const visible = Boolean(query)

  const handleDismiss = () => history.push(history.location.pathname)

  return (
    <Modal
      visible={visible}
      title="View Profile"
      onClose={handleDismiss}
      className={styles.viewProfile}
    >
      {user ? (
        <>
          <Text block strong size="md" type="black">
            {t(copy.Components.ViewProfile.picture)}
          </Text>

          <div className={styles.avatarEmpty}>
            <Icon name="user" size="2xl" type="white" />
          </div>

          <InfoRow
            icon="smiley-f"
            label={t(copy.Components.ViewProfile.labelName)}
            value={`${user.first_name} ${user.last_name}`}
          />

          <InfoRow
            icon="envelope"
            label={t(copy.Components.ViewProfile.labelEmail)}
            value={user.email}
          />
        </>
      ) : (
        <Spinner centered size="xl" className={styles.spinner} />
      )}
    </Modal>
  )
}

const InfoRow = (props: { icon: IconName; label: string; value: string }) => {
  const { icon, label, value } = props
  return (
    <div className={styles.row}>
      <div className={styles.rowIcon}>
        <Icon name={icon} type="light" />
      </div>
      <Text block noLeading>
        {value}
      </Text>
      <Text block size="xs" type="light">
        {label}
      </Text>
    </div>
  )
}

export default ViewProfile
