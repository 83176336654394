export const COLORS = {
  'primary': 'rgb(69, 135, 239)', // #4586ef
  'primaryDark': '#3151AA',
  'primary-05': 'rgba(69, 135, 239, 0.05)',
  'success': 'rgb(46, 176, 98)', // #2eb062
  'danger': 'rgb(198, 27, 66)', // #c61b43
  'warning': 'rgb(245, 166, 35)', // #f5a523
  'default': 'rgb(49, 81, 170)', // #3151aa
  'default-80': 'rgba(49, 81, 170, 0.8)',
  'default-lighter': 'rgb(43, 108, 176)',

  'gray': 'rgb(81, 94, 107)', // #515e6b
  'gray-light': 'rgb(132, 146, 166)', // #8492a6
  'gray-lighter': 'rgb(151, 158, 166)', // #979ea6
  'gray-lightest': 'rgb(217, 217, 217)', // #d9d9d9
  'new-gray': 'rgb(203, 213, 224)', // #cbd5e0

  'offwhite-dark': 'rgb(234, 234, 234)', // ##eaeaea
  'offwhite': 'rgb(241, 241, 241)', // #f1f1f1
  'offwhite-light': 'rgb(247, 247, 247)', // #f7f7f7

  'border-light': 'rgb(229, 233, 242)', // #e5e9f2
  'shadow': 'rgba(158, 158, 158, 0.5)',

  'white': 'rgb(255, 255, 255)', // #ffffff
  'black': 'rgb(1, 23, 56)', // #011738

  'true-black-60': 'rgba(0, 0, 0, 0.6)',
}
