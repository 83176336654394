import React from 'react'
import { Helmet } from 'react-helmet'

import useTranslation from 'hooks/useTranslation'
import {
  IS_PROD,
  IS_PH,
  IS_ENABLE_GTM,
  GOOGLE_ANALYTICS_GTAG,
} from 'constants/config'

const DocumentHead = () => {
  const { t, copy } = useTranslation()
  return (
    <Helmet>
      <meta name="description" content={t(copy.Components.Meta.title)} />
      <title>{t(copy.Components.Meta.title)}</title>

      {/* Crazy Egg */}
      {IS_PROD && IS_PH && (
        <script
          async
          type="text/javascript"
          src="//script.crazyegg.com/pages/scripts/0087/7611.js"
        />
      )}

      {/* Global site tag (gtag.js) - Google Analytics */}
      {/* <></> does not work on react helmet */}
      {IS_ENABLE_GTM && (
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_GTAG}`}
        />
      )}
      {IS_ENABLE_GTM && (
        <script>
          {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${GOOGLE_ANALYTICS_GTAG}');
            `}
        </script>
      )}
    </Helmet>
  )
}

export default DocumentHead
