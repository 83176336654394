export enum DashboardQuery {
  viewProfile = 'view_profile',
}

export enum HomeQuery {
  welcome = 'welcome',
  partnershipSurvey = 'survey',
}

export enum ProfileQuery {
  edit = 'edit',
  add = 'add',
}

export enum ProfileQueryEditParam {
  basic = 'basic',
}

export enum ProfileQueryAddParam {
  number = 'number',
  email = 'email',
}

export enum ApplicationQuery {
  id = 'id',
  updateVA = 'updateVA',
}

export enum SearchQuery {
  q = 'q',
}

export enum IntegrationsQuery {
  downloadKey = 'downloadKey',
}

export enum AdmissionsQuery {
  id = 'id',
}

export enum PermissionsQuery {
  add = 'add',
  edit = 'edit',
  delete = 'delete',
}

export enum LoginQuery {
  redirect = 'redirect',
}
