export enum OTPForm {
  heading = 'otpForm.heading',
  subheading = 'otpForm.subheading',
  countdown = 'otpForm.countdown',
  receive = 'otpForm.receive',
  resend = 'otpForm.resend',
  verify = 'otpForm.verify',
  mail = 'otpForm.mail',
  alertResentOtp = 'otpForm.alertResentOtp',
  alertInvalidOtp = 'otpForm.alertInvalidOtp',
}
