export enum Routes {
  index = '/',
  register = '/register',
  login = '/login',
  forgotPassword = '/forgot_password',

  profile = '/profile',
  setPassword = '/set_password',
  applications = '/applications',
  metrics = '/metrics',
  search = '/search',
  reports = '/reports',
  services = '/services',
  admins = '/admins',
  adminForm = '/admins/form',
  account = '/account',
  help = '/help',
  leadsWelcome = '/leads',
  leadsContent = '/leads/content',
  admissions = '/admissions',
  risk = '/risk',
  partnerProfile = '/partner-profile',
  integrations = '/integrations',
  digestValidator = '/digest-validator',
  permissions = '/permissions',
  lite = '/lite',
  liteLandingPreview = '/lite/landing-preview',
}
