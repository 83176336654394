import React from 'react'
import { Popover, Tag } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import cx from 'classnames'
import { FormNew24Filled, FormNew24Regular } from '@fluentui/react-icons'

import Icon, { IconName } from 'components/Icon'
import { Text } from 'components/Typography'
import Button from 'components/Button/index'

import useTranslation from 'hooks/useTranslation'
import { Routes } from 'constants/routes'
import { logLeadsOnSidebar } from 'services/analytics/events'

import styles from './Sidebar.module.scss'
import useSidebar from 'hooks/useSidebar'
import { useLoansVArequired } from 'services/loans'
import { Item } from '.'
import history from 'utils/history'

import PolygonIcon from 'assets/icons/polygon.svg'
import PolygonIconInactive from 'assets/icons/polygon_inactive.svg'

import { COLORS } from 'constants/colors'

type Props = {
  name: string
  path: string
  subMenu?: Omit<Item, 'icon'>[]
  active: boolean
  icon: IconName
  label: string
  newTag?: boolean
  highlight?: boolean
  showLeadsMenuCoachmarking: boolean
  closeCoachmarking: () => void
  isVARequestenabled: boolean
}

const RenderLinkedItem = ({
  highlight,
  path,
  active,
  subMenu,
  icon,
  label,
  newTag,
  name,
  showLeadsMenuCoachmarking,
  closeCoachmarking,
  isVARequestenabled,
}: Props) => {
  const { t, copy } = useTranslation()
  const { collapsed, setCollapsed } = useSidebar()
  const { data: loansVArequired } = useLoansVArequired()
  const location = useLocation()

  const CoachmarkingContent = (
    <div className={styles.coachMarking}>
      <Text block strong size="xl" type="black">
        {t(copy.Components.Sidebar.leadsCoachmarkingTitle)}
      </Text>
      <Text block size="sm" type="black" className={styles.content}>
        {t(copy.Components.Sidebar.leadsCoachmarkingContent)}
      </Text>
      <Button block type="primary" onClick={closeCoachmarking}>
        <Link to={Routes.leadsWelcome}>{t(copy.Touchables.Buttons.next)}</Link>
      </Button>
    </div>
  )

  if (highlight) {
    return (
      <Popover
        visible={showLeadsMenuCoachmarking}
        placement="rightTop"
        content={CoachmarkingContent}
      >
        <Link
          to={path}
          className={cx(
            styles.item,
            active && styles.itemActive,
            highlight && styles.highlight
          )}
          onClick={() => {
            closeCoachmarking()
            if (name === 'leads') {
              logLeadsOnSidebar()
            }
          }}
        >
          {name === 'admissions' ? (
            <>
              {active ? (
                <FormNew24Filled style={{ color: COLORS['default-lighter'] }} />
              ) : (
                <FormNew24Regular style={{ color: COLORS['gray'] }} />
              )}
            </>
          ) : (
            <Icon
              name={icon}
              size="md"
              type={active ? 'primary-lighter' : undefined}
              className={styles.itemIcon}
            />
          )}

          <Text
            ellipsize
            size="sm"
            type={active ? 'primary-lighter' : undefined}
            className={styles.itemLabel}
          >
            {t(label)}
          </Text>
          {subMenu}
          {!collapsed && newTag && <Tag>{t(copy.Components.Sidebar.new)}</Tag>}
        </Link>
      </Popover>
    )
  }

  const hasSubMenu = subMenu ? subMenu.length > 0 : false
  const firstSubMenuPath =
    subMenu && subMenu.length > 0 ? subMenu?.[0].path : path

  return (
    <>
      <Link
        to={hasSubMenu ? firstSubMenuPath : path}
        className={cx(styles.item, active && styles.itemActive)}
        onClick={() => {
          if (name === 'leads') {
            logLeadsOnSidebar()
          }

          if (hasSubMenu && collapsed) {
            setCollapsed(false)
          }
        }}
      >
        {name === 'admissions' ? (
          <>
            {active ? (
              <FormNew24Filled style={{ color: COLORS['default-lighter'] }} />
            ) : (
              <FormNew24Regular style={{ color: COLORS['gray'] }} />
            )}
          </>
        ) : (
          <Icon
            name={icon}
            size="md"
            type={active ? 'primary-lighter' : undefined}
            className={styles.itemIcon}
          />
        )}

        <Text
          ellipsize
          size="sm"
          type={active ? 'primary-lighter' : undefined}
          className={styles.itemLabel}
        >
          {t(label)}
        </Text>

        {!collapsed && newTag && (
          <Tag color="processing">{t(copy.Components.Sidebar.new)}</Tag>
        )}

        {subMenu && subMenu.length > 0 && !collapsed && (
          <img
            src={active ? PolygonIcon : PolygonIconInactive}
            alt="polygon-icon"
          />
        )}
        {name === 'applications' &&
          !collapsed &&
          isVARequestenabled &&
          loansVArequired &&
          loansVArequired?.length > 0 && (
            <Tag className={styles.VARequestBadge}>
              {loansVArequired?.length}
            </Tag>
          )}
      </Link>

      {subMenu && subMenu?.length > 0 && !collapsed && active && (
        <div
          className={styles.subMenuContainer}
          style={{ paddingBottom: active ? '4px' : 0 }}
        >
          {subMenu.map((menu, idx) => {
            const isSubMenuActive = menu.path === location.pathname
            return (
              <div
                onClick={() => history.push(menu.path)}
                key={idx}
                className={cx(styles.subMenuItem)}
              >
                <p
                  className={styles.subMenuItemLabel}
                  style={{
                    fontWeight: isSubMenuActive ? 'bold' : 'normal',
                    color: isSubMenuActive ? COLORS.primaryDark : 'auto',
                  }}
                >
                  {t(menu.label)}
                </p>
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}

export default RenderLinkedItem
