import {
  Header,
  Sidebar,
  PasswordForm,
  ViewProfile,
  Table,
  RangePicker,
  Meta,
  SessionTimeout,
  LoanStatusDashboard,
} from 'constants/copy/components'
import { APP_NAME } from 'constants/countryInfo'

export const HEADER_COPY: CopyRecord<Header> = {
  [Header.searchPlaceholder]:
    'Search for student ID, application reference number, or name of applicant...',
  [Header.MonthlyReportSearchPlaceholder]:
    'Search institution, school, date, or number of students',
}

export const SIDEBAR_COPY: CopyRecord<Sidebar> = {
  [Sidebar.main]: 'Main',
  [Sidebar.more]: 'More',
  [Sidebar.new]: 'New',
  [Sidebar.dashboard]: 'My Dashboard',
  [Sidebar.applications]: 'Loans',
  [Sidebar.reports]: 'Reports',
  [Sidebar.leads]: 'Refer to Bukas',
  [Sidebar.admissions]: 'Admissions',
  [Sidebar.recruitEnrollees]: 'Recruit Enrollees',
  [Sidebar.recruitEnrolleesAnswer]: 'Answer our survey',
  [Sidebar.recruitEnrolleesModalBody]:
    'Would you be interested in an enrollee recruitment system? This feature will allow you to actively pursue enrollees by sending them an SMS to sign up with Bukas from your dashboard.',
  [Sidebar.recruitEnrolleesModalBodySurvey]:
    'Would like to help us develop this feature? You may answer this survey to help us learn more about your needs.',
  [Sidebar.recruitEnrolleesNotInterested]: 'Not Interested',
  [Sidebar.recruitEnrolleesInterested]: `I'm Interested`,
  [Sidebar.services]: 'Services',
  [Sidebar.administrators]: 'Users & Permissions',
  [Sidebar.permissions]: 'Users & Permissions',
  [Sidebar.settings]: 'Account Settings',
  [Sidebar.help]: 'Help Center',

  [Sidebar.profile]: 'Profile',
  [Sidebar.seeProfile]: 'See your partner profile',
  [Sidebar.risk]: 'Wholesale',
  [Sidebar.metrics]: 'Metrics',
  [Sidebar.reportsMetrics]: 'Reports & Metrics',
  [Sidebar.comingSoon]: 'Coming Soon',
  [Sidebar.profileSettings]: 'Profile',
  [Sidebar.integrations]: 'Developer Tools',
  [Sidebar.apiDocs]: 'API Docs, Keys, & Webhooks',
  [Sidebar.digestValidator]: 'Digest Validator',
  [Sidebar.lite]: 'BukasPay',
  [Sidebar.logout]: 'Sign out',

  [Sidebar.leadsCoachmarkingTitle]: 'Check out Leads Management!',
  [Sidebar.leadsCoachmarkingContent]:
    'Recruit new enrollees and improve retention using your partner dashboard.',

  [Sidebar.schoolIconGroupText]: 'Campus{{plural}}',
}

export const SESSION_TIMEOUT_COPY: CopyRecord<SessionTimeout> = {
  [SessionTimeout.heading]: 'Your session is about to end.',
  [SessionTimeout.staySignedIn]: 'Stay signed in',
  [SessionTimeout.infoCountdown]: `You've been inactive for a while. For your security, we'll automatically sign you out. Your session will expire in {{min}} min {{secs}} secs Please click "Stay signed in" to continue or "Sign out" if you're done.`,
}

export const PASSWORD_FORM_COPY: CopyRecord<PasswordForm> = {
  [PasswordForm.ruleLowercase]: 'Contains at least one lowercase letter',
  [PasswordForm.ruleUppercase]: 'Contains at least one uppercase letter',
  [PasswordForm.ruleNumber]: 'Contains at least one number',
  [PasswordForm.ruleSpecial]: 'Contains at least one special character',
  [PasswordForm.ruleLength]: 'At least 8 characters long',
}

export const VIEW_PROFILE_COPY: CopyRecord<ViewProfile> = {
  [ViewProfile.picture]: 'Profile Picture',
  [ViewProfile.labelName]: 'Full name',
  [ViewProfile.labelMobile]: 'Mobile number',
  [ViewProfile.labelEmail]: 'Email address',
}

export const TABLE_COPY: CopyRecord<Table> = {
  [Table.noData]: 'No data',
  [Table.pagination]: 'Showing {{start}}-{{end}} of {{total}}',
}

export const RANGE_PICKER_COPY: CopyRecord<RangePicker> = {
  [RangePicker.today]: 'Today',
  [RangePicker.lastWeek]: 'Last Week',
  [RangePicker.lastMonth]: 'Last Month',
  [RangePicker.overall]: 'Overall',
}

export const META_COPY: CopyRecord<Meta> = {
  [Meta.title]: `${APP_NAME} Partner Dashboard`,
}

export const LOAN_STATUS_DASHBOARD: CopyRecord<LoanStatusDashboard> = {
  [LoanStatusDashboard.notYetSubmitted]: `Not Yet Submitted`,
  [LoanStatusDashboard.initialReview]: `Initial Review`,
  [LoanStatusDashboard.pendingStudentResolution]: `Pending Student Resolution`,
  [LoanStatusDashboard.finalReview]: `Final Review`,
  [LoanStatusDashboard.forStudentConfirmation]: `For Student's Confirmation`,
  [LoanStatusDashboard.forDisbursement]: `For Disbursement`,
  [LoanStatusDashboard.agreementActivated]: `Agreement Activated`,
  [LoanStatusDashboard.closed]: `Closed`,
  [LoanStatusDashboard.canceled]: `Canceled`,
  [LoanStatusDashboard.rejected]: `Rejected`,
  [LoanStatusDashboard.tooltipInitialReview]: `Application has been received and is under review`,
  [LoanStatusDashboard.tooltipNotYetSubmitted]: `Pelajar belum memulai atau menyelesaikan pengajuan ke Danacita`,
  [LoanStatusDashboard.tooltipPendingStudentResolution]: `Waiting for Student to complete revisions needed`,
  [LoanStatusDashboard.tooltipFinalReview]: `Pending final decision from Danacita team`,
  [LoanStatusDashboard.tooltipForStudentConfirmation]: `Pending signature from Student`,
  [LoanStatusDashboard.tooltipForDisbursement]: `Preparing payment to school`,
  [LoanStatusDashboard.tooltipAgreementActivated]: `Payment disbursed to school`,
  [LoanStatusDashboard.tooltipClosed]: `Installment plan has been fully paid`,
  [LoanStatusDashboard.tooltipCanceled]: `Application has been cancelled`,
  [LoanStatusDashboard.tooltipRejected]: `Application has been rejected`,
}
