import { Labels, Errors, Messages } from 'constants/copy/inputs'

export const INPUTS_COPY: CopyRecord<Labels> = {
  [Labels.firstName]: 'Nama Depan',
  [Labels.lastName]: 'Name Belakang',
  [Labels.role]: 'Jabatan',
  [Labels.campus]: 'Sekolah',
  [Labels.allSchool]: 'Semua Sekolah',
  [Labels.disbursementNotification]: 'Pemberitahuan Pencairan Dana',
  [Labels.POCLabel]: 'Menerima pemberitahuan pencairan dana melalui email',
  [Labels.access]: 'Akses',
  [Labels.email]: 'Email',
  [Labels.password]: 'Kata sandi',
  [Labels.label]: 'Label',
  [Labels.type]: 'Tipe',
  [Labels.contactNumber]: 'Nomor kontak',
  [Labels.partnerName]: 'Nama Mitra',
  [Labels.province]: 'Provinsi',
  [Labels.city]: 'Kota',
  [Labels.streetAddress]: 'Alamat',
}

export const INPUT_ERRORS_COPY: CopyRecord<Errors> = {
  [Errors.required]: 'Wajib diisi',
  [Errors.requestThrottle]:
    'Anda telah melebihi jumlah maksimum percobaan masuk. Silahkan coba beberapa saat lagi.',
  [Errors.invalidCredentials]: 'Email atau kata sandi Anda salah',
  [Errors.multipleExistingUser]:
    'User [{{usernames}}] sudah terdaftar. Untuk melanjutkan proses silahkan hapus dari pilihan.',
  [Errors.wrongEmailFormat]: 'Masukkan alamat email yang benar',
  [Errors.duplicateContactNumber]: 'Nomor kontak sudah digunakan',
  [Errors.duplicateContactEmail]: 'Email sudah digunakan',
  [Errors.multipleExistingEmail]: 'Email [{{emails}}] sudah digunakan',
  [Errors.multipleExistingEmailInPostDraft]:
    'Email [{{emails}}] sudah terdaftar. Untuk melanjutkan proses silahkan hapus dari pilihan.',
  [Errors.invalidURL]: 'Alamat URL yang anda masukkan tidak valid',
  [Errors.phoneNumberTooShort]: 'Nomor Hp harus lebih dari 9 angka.',
}

export const INPUT_MESSAGES_COPY: CopyRecord<Messages> = {
  [Messages.selectDate]: 'Pilih tanggal',
  [Messages.startDate]: 'Tanggal awal',
  [Messages.endDate]: 'Tanggal akhir',
}
