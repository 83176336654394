import { COUNTRY } from 'constants/config'

import appIconSvg from './icon.svg'

const getAppIconSvg = (country: Country) => {
  switch (country) {
    case 'PH':
      return appIconSvg
    case 'ID':
      return appIconSvg
  }
}

const appLogo = getAppIconSvg(COUNTRY)

export default appLogo
