export enum Header {
  searchPlaceholder = 'header.searchPlaceholder',
  MonthlyReportSearchPlaceholder = 'header.MonthlyReportSearchPlaceholder',
}

export enum Sidebar {
  main = 'sidebar.main',
  more = 'sidebar.more',
  new = 'sidebar.new',
  dashboard = 'sidebar.menu.dashboard',
  applications = 'sidebar.menu.applications',
  reports = 'sidebar.menu.reports',
  leads = 'sidebar.menu.leads',
  admissions = 'sidebar.menu.admissions',
  services = 'sidebar.menu.services',
  recruitEnrollees = 'sidebar.menu.recruitEnrollees',
  recruitEnrolleesAnswer = 'sidebar.menu.recruitEnrolleesAnswer',
  recruitEnrolleesModalBody = 'sidebar.menu.recruitEnrolleesModalBody',
  recruitEnrolleesNotInterested = 'sidebar.menu.recruitEnrolleesNotInterested',
  recruitEnrolleesInterested = 'sidebar.menu.recruitEnrolleesInterested',
  recruitEnrolleesModalBodySurvey = 'sidebar.menu.recruitEnrolleesModalBodySurvey',
  administrators = 'sidebar.menu.administrators',
  permissions = 'sidebar.menu.permissions',
  settings = 'sidebar.menu.settings',
  help = 'sidebar.menu.help',

  profile = 'sidebar.menu.profile',
  seeProfile = 'sidebar.menu.seeProfile',
  risk = 'sidebar.menu.risk',
  metrics = 'sidebar.menu.metrics',
  reportsMetrics = 'sidebar.menu.reportsMetrics',
  comingSoon = 'sidebar.menu.comingSoon',
  profileSettings = 'sidebar.menu.profileSettings',
  integrations = 'sidebar.menu.integrations',
  apiDocs = 'sidebar.menu.apiDocs',
  digestValidator = 'sidebar.menu.digestValidator',
  lite = 'sidebar.menu.lite',
  logout = 'sidebar.menu.logout',

  leadsCoachmarkingTitle = 'sidebar.menu.leadsCoachmarkingTitle',
  leadsCoachmarkingContent = 'sidebar.menu.leadsCoachmarkingContent',

  schoolIconGroupText = 'sidebar.menu.schoolIconGroupText',
}

export enum SessionTimeout {
  heading = 'sessionTimeout.heading',
  infoCountdown = 'sessionTimeout.infoCountdown',
  staySignedIn = 'sessionTimeout.staySignedIn',
}

export enum PasswordForm {
  ruleLowercase = 'formPassword.ruleLowercase',
  ruleUppercase = 'formPassword.ruleUppercase',
  ruleNumber = 'formPassword.ruleNumber',
  ruleSpecial = 'formPassword.ruleSpecial',
  ruleLength = 'formPassword.ruleLength',
}

export enum ViewProfile {
  picture = 'viewProfile.picture',
  labelName = 'viewProfile.labelName',
  labelMobile = 'viewProfile.labelMobile',
  labelEmail = 'viewProfile.labelEmail',
}

export enum Table {
  noData = 'table.noData',
  pagination = 'table.pagination',
}

export enum RangePicker {
  today = 'rangePicker.today',
  lastWeek = 'rangePicker.lastWeek',
  lastMonth = 'rangePicker.lastMonth',
  overall = 'rangePicker.overall',
}

export enum Meta {
  title = 'meta.title',
}

export enum LoanStatusDashboard {
  notYetSubmitted = 'loanStatusDashboard.notYetSubmitted',
  initialReview = 'loanStatusDashboard.initialReview',
  pendingStudentResolution = 'loanStatusDashboard.pendingStudentResolution',
  finalReview = 'loanStatusDashboard.finalReview',
  forStudentConfirmation = 'loanStatusDashboard.forStudentConfirmation',
  forDisbursement = 'loanStatusDashboard.forDisbursement',
  agreementActivated = 'loanStatusDashboard.agreementActivated',
  closed = 'loanStatusDashboard.closed',
  canceled = 'loanStatusDashboard.canceled',
  rejected = 'loanStatusDashboard.rejected',
  tooltipNotYetSubmitted = 'loanStatusDashboard.tooltipNotYetSubmitted',
  tooltipInitialReview = 'loanStatusDashboard.tooltipInitialReview',
  tooltipPendingStudentResolution = 'loanStatusDashboard.tooltipPendingStudentResolution',
  tooltipFinalReview = 'loanStatusDashboard.tooltipFinalReview',
  tooltipForStudentConfirmation = 'loanStatusDashboard.tooltipForStudentConfirmation',
  tooltipForDisbursement = 'loanStatusDashboard.tooltipForDisbursement',
  tooltipAgreementActivated = 'loanStatusDashboard.tooltipAgreementActivated',
  tooltipClosed = 'loanStatusDashboard.tooltipClosed',
  tooltipCanceled = 'loanStatusDashboard.tooltipCanceled',
  tooltipRejected = 'loanStatusDashboard.tooltipRejected',
}
