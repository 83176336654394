import React from 'react'
import cx from 'classnames'
import { Button as AntdButton } from 'antd'

import styles from './Button.module.scss'

type Props = {
  id?: string
  block?: boolean
  children?: React.ReactNode
  className?: string
  disabled?: boolean
  htmlType?: 'reset' | 'submit'
  loading?: boolean
  onClick?: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  shadow?: boolean
  size?: 'small' | 'large'
  icon?: React.ReactNode
  type?:
    | 'danger'
    | 'dashed'
    | 'ghost'
    | 'light'
    | 'link'
    | 'primary'
    | 'secondary'
    | 'outlined'
    | 'outlined gray'
  vCenter?: boolean
}

const Button = (props: Props) => {
  const {
    className,
    shadow,
    size = 'large',
    type = 'primary',
    vCenter,
    ...buttonProps
  } = props
  const btnType =
    type === 'secondary' ||
    type === 'danger' ||
    type === 'light' ||
    type === 'outlined' ||
    type === 'outlined gray'
      ? undefined
      : type
  const cn = cx(
    styles.button,
    {
      [styles.block]: buttonProps.block,
      [styles.shadow]: shadow,
      [styles.vCenter]: vCenter,
      [styles.secondary]: type === 'secondary',
      [styles.outlined]: type === 'outlined',
      [styles.outlinedGray]: type === 'outlined gray',
      [styles.link]: type === 'link',
      [styles.light]: type === 'light',
      [styles.small]: size === 'small',
    },
    className
  )
  return (
    <AntdButton
      className={cn}
      size={size}
      type={btnType}
      danger={type === 'danger'}
      {...buttonProps}
    />
  )
}

export default Button
