import request from './request'

export const login = (data: { username: string; password: string }) =>
  request.post<{ detail: string }>(`/partners/auth/login/`, data)

export const tempLogin = (data: { key: string }) =>
  request.post<{ access_token: string; refresh: string }>(
    `/users/temp_login/`,
    data
  )

export const resetPassword = (data: { email: string }) =>
  request.post<{
    detail: string
  }>(`/users/reset_password/`, data)

export const setPassword = (data: { password: string }) =>
  request.post<{
    detail: string
  }>(`/users/set_password/`, data)

export const sendOtp = (data: { email: string }) =>
  request.post<{ detail: string }>(`/partners/users/send_otp/`, data)

export const verifyOtp = (data: { email: string; otp: string }) =>
  request.post<{ access_token: string; refresh: string }>(
    `/partners/users/verify_otp/`,
    data
  )

export const logout = (data: { refresh_token: string }) =>
  request.post<{ detail: string }>(`/partners/auth/logout/`, data)
