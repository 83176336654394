import React from 'react'
import ReactDOM from 'react-dom'
import 'services/analytics/setup'
import App from 'App'
import 'assets/css/index.css'
import reportWebVitals from './reportWebVitals'
import { initializeI18n } from 'utils/i18n'
import 'firebase/analytics'

initializeI18n()

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
