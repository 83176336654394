import './setup'
import firebase from 'firebase/app'
import 'firebase/analytics'

const analytics = firebase.analytics()

const logEvent = (
  name: string,
  properties?:
    | {
        [key: string]: string | number | boolean
      }
    | undefined
) => {
  return analytics.logEvent(name, properties)
}

export const logLogin = (props: { email: string }) =>
  logEvent('login_success', { email: props.email })

export const logExportReports = () => logEvent('clicks_export_in_reports')

export const logExportApplications = () =>
  logEvent('clicks_export_in_applications')

export const logExportDisbursementReports = () =>
  logEvent('clicks_export_disbursement_reports')

export const logFilterApplication = () => logEvent('clicks_filter_application')

export const logPost = (props: { loanId: number }) =>
  logEvent('clicks_post_in_application_overview', { loanId: props.loanId })

export const logCloseFirstModalRecruitEnrollees = () =>
  logEvent('clicks_close_first_modal_recruit_enrollees')

export const logCloseSecondModalRecruitEnrollees = () =>
  logEvent('clicks_close_second_modal_recruit_enrollees')

export const logNotInterestedRecruitEnrollees = () =>
  logEvent('clicks_not_interested_recruit_enrollees')

export const logInterestedRecruitEnrollees = () =>
  logEvent('clicks_interested_recruit_enrollees')

export const logAnswerSurveyRecruitEnrollees = () =>
  logEvent('clicks_answer_the_survey_recruit_enrollees')

export const logPostNewApplications = () => logEvent('post_new_applications')

export const logDownloadReport = () => logEvent('download_report')

export const logDownloadReportAndSendNotification = () =>
  logEvent('download_report_and_send_notification')

export const logLeadsOnSidebar = () => logEvent('clicks_leads_on_sidebar')

export const logAddLead = () => logEvent('add_lead')

export const logSaveLead = () => logEvent('save_lead')

export const logSaveAsDraft = () => logEvent('save_as_Draft')
