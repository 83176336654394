import './setup'
import firebase from 'firebase/app'
import 'firebase/remote-config'

const remoteConfig = firebase.remoteConfig()

export const isLeadsManagementDisabled = async () => {
  try {
    await remoteConfig.fetchAndActivate()
    const leadsManagement = remoteConfig.getValue('leads_management')
    const isLeadsManagementDisabled = leadsManagement.asString() === 'disabled'
    return isLeadsManagementDisabled
  } catch (err) {
    return false
  }
}

export const isRiskSharingModuleDisabled = async () => {
  try {
    await remoteConfig.fetchAndActivate()
    const riskModule = remoteConfig.getValue('risk_module')
    const isRiskSharingModuleDisabled = riskModule.asString() === 'disabled'
    return isRiskSharingModuleDisabled
  } catch (err) {
    return false
  }
}

export const isProfileSettingsModuleDisabled = async () => {
  try {
    await remoteConfig.fetchAndActivate()
    const profileSettingsModule = remoteConfig.getValue(
      'profile_settings_module'
    )
    const isProfileSettingsModuleDisabled =
      profileSettingsModule.asString() === 'disabled'
    return isProfileSettingsModuleDisabled
  } catch (err) {
    return false
  }
}
