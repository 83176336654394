import React from 'react'
import cx from 'classnames'
import { Tooltip as AntdTooltip } from 'antd'
import { TooltipProps } from 'antd/lib/tooltip'

import { Text } from 'components/Typography'
import styles from './Tooltip.module.scss'

type Props = {
  title: string
  type?: 'danger' | 'light' | 'primary' | 'success' | 'warning'
}

const Tooltip = (props: Props & TooltipProps) => {
  const { title, type = 'primary', ...tooltipProps } = props
  const cn = cx(styles.tooltip, {
    [styles.danger]: type === 'danger',
    [styles.light]: type === 'light',
    [styles.primary]: type === 'primary',
    [styles.success]: type === 'success',
    [styles.warning]: type === 'warning',
  })
  return (
    <AntdTooltip
      {...tooltipProps}
      overlayClassName={cn}
      title={<TooltipTitle title={title} />}
    />
  )
}

const TooltipTitle = (props: { title: string }) => {
  const { title } = props
  return (
    <Text block noLeading strong uppercase size="xs" type="white">
      {title}
    </Text>
  )
}

export default Tooltip
