import {
  Header,
  Sidebar,
  PasswordForm,
  ViewProfile,
  Table,
  RangePicker,
  Meta,
  SessionTimeout,
  LoanStatusDashboard,
} from 'constants/copy/components'
import { APP_NAME } from 'constants/countryInfo'

export const HEADER_COPY: CopyRecord<Header> = {
  [Header.searchPlaceholder]:
    'Cari ID pelajar, nomor referensi aplikasi, atau nama pelajar...',
  [Header.MonthlyReportSearchPlaceholder]:
    'Cari berdasarkan institusi dan tanggal laporan',
}

export const SIDEBAR_COPY: CopyRecord<Sidebar> = {
  [Sidebar.main]: 'Menu Utama',
  [Sidebar.more]: 'Lainnya',
  [Sidebar.new]: 'Baru',
  [Sidebar.dashboard]: 'Dasbor',
  [Sidebar.applications]: 'Aplikasi',
  [Sidebar.reports]: 'Laporan',
  [Sidebar.leads]: 'Rekomendasikan Pelajar',
  [Sidebar.admissions]: 'Admissions',
  [Sidebar.recruitEnrollees]: 'Rekrut Pendaftar',
  [Sidebar.recruitEnrolleesAnswer]: 'Jawab survey kami',
  [Sidebar.recruitEnrolleesModalBody]:
    'Apakah anda tertarik untuk mencoba sistem rekrutment? Fitur ini akan memungkinkan Anda untuk mengirimkan SMS kepada rekrutment/pelajar yang ingin mendaftar Danacita melaui Dashboard Anda.',
  [Sidebar.recruitEnrolleesModalBodySurvey]:
    'Apakah Anda ingin membantu kami untuk mengembangkan fitur ini? Anda akan menjawab survey untuk membantu kami mengetahui lebih tentang apa yang Anda butuhkan untuk fitur ini.',
  [Sidebar.recruitEnrolleesNotInterested]: 'Tidak Tertarik',
  [Sidebar.recruitEnrolleesInterested]: 'Saya Tertarik!',
  [Sidebar.services]: 'Servis',
  [Sidebar.administrators]: 'Administrator',
  [Sidebar.permissions]: 'Administrator',
  [Sidebar.settings]: 'Pengaturan',
  [Sidebar.help]: 'Pusat Bantuan',

  [Sidebar.profile]: 'Profil',
  [Sidebar.seeProfile]: 'Lihat profil mitra Anda',
  [Sidebar.risk]: 'Blocking Access',
  [Sidebar.metrics]: 'Metrik',
  [Sidebar.reportsMetrics]: 'Laporan & Metrik',
  [Sidebar.comingSoon]: 'Segera akan datang',
  [Sidebar.profileSettings]: 'Pengaturan Profil',
  [Sidebar.integrations]: 'Developer Tools',
  [Sidebar.apiDocs]: 'API Docs, Keys, & Webhooks',
  [Sidebar.digestValidator]: 'Digest Validator',
  [Sidebar.lite]: 'Lite+',
  [Sidebar.logout]: 'Keluar',

  [Sidebar.leadsCoachmarkingTitle]: 'Check out Leads Management!',
  [Sidebar.leadsCoachmarkingContent]:
    'Recruit new enrollees and improve retention using your partner dashboard.',

  [Sidebar.schoolIconGroupText]: 'Kampus',
}

export const SESSION_TIMEOUT_COPY: CopyRecord<SessionTimeout> = {
  [SessionTimeout.heading]: 'Sesi Anda akan segera berakhir.',
  [SessionTimeout.staySignedIn]: 'Tetap Masuk',
  [SessionTimeout.infoCountdown]: `Kamu sepertinya sedang tidak aktif. Untuk alasan keamanan, dan kamu akan otomatis keluar. Sesi kamu akan berakhir pada {{min}} menit {{secs}} detik. Silahkan klik "Tetap Masuk" untuk melanjutkan atau "Keluar" jika ingin selesai.`,
}

export const PASSWORD_FORM_COPY: CopyRecord<PasswordForm> = {
  [PasswordForm.ruleLowercase]: 'Harus memiliki setidaknya satu huruf kecil',
  [PasswordForm.ruleUppercase]: 'Harus memiliki setidaknya satu huruf besar',
  [PasswordForm.ruleNumber]: 'Harus memiliki setidaknya satu angka',
  [PasswordForm.ruleSpecial]: 'Harus memiliki setidaknya satu karakter khusus',
  [PasswordForm.ruleLength]: 'Setidaknya 8 karakter',
}

export const VIEW_PROFILE_COPY: CopyRecord<ViewProfile> = {
  [ViewProfile.picture]: 'Foto Profil',
  [ViewProfile.labelName]: 'Nama lengkap',
  [ViewProfile.labelMobile]: 'Nomor HP',
  [ViewProfile.labelEmail]: 'Email',
}

export const TABLE_COPY: CopyRecord<Table> = {
  [Table.noData]: 'Tidak ada data',
  [Table.pagination]: 'Lihat {{start}}-{{end}} dari {{total}}',
}

export const RANGE_PICKER_COPY: CopyRecord<RangePicker> = {
  [RangePicker.today]: 'Hari ini',
  [RangePicker.lastWeek]: 'Minggu lalu',
  [RangePicker.lastMonth]: 'Bulan lalu',
  [RangePicker.overall]: 'Secara keseluruhan',
}

export const META_COPY: CopyRecord<Meta> = {
  [Meta.title]: `${APP_NAME} Mitra Dashboard`,
}

export const LOAN_STATUS_DASHBOARD: CopyRecord<LoanStatusDashboard> = {
  [LoanStatusDashboard.notYetSubmitted]: `Belum Mengajukan`,
  [LoanStatusDashboard.initialReview]: `Pengecekan Tahap Awal`,
  [LoanStatusDashboard.pendingStudentResolution]: `Menunggu Revisi Pelajar`,
  [LoanStatusDashboard.finalReview]: `Pengecekan Tahap Akhir`,
  [LoanStatusDashboard.forStudentConfirmation]: `Menunggu Konfirmasi Pelajar`,
  [LoanStatusDashboard.forDisbursement]: `Proses Pencairan`,
  [LoanStatusDashboard.agreementActivated]: `Pembiayaan Aktif`,
  [LoanStatusDashboard.closed]: `Lunas`,
  [LoanStatusDashboard.canceled]: `Batal`,
  [LoanStatusDashboard.rejected]: `Ditolak`,
  [LoanStatusDashboard.tooltipNotYetSubmitted]: `Pelajar belum memulai atau menyelesaikan pengajuan ke Danacita`,
  [LoanStatusDashboard.tooltipInitialReview]: `Aplikasi sedang dalam proses review`,
  [LoanStatusDashboard.tooltipPendingStudentResolution]: `Menunggu perbaikan dokumen atau informasi`,
  [LoanStatusDashboard.tooltipFinalReview]: `Menunggu keputusan akhir dari Tim Danacita`,
  [LoanStatusDashboard.tooltipForStudentConfirmation]: `Menunggu dokumen perjanjian ditandatangani`,
  [LoanStatusDashboard.tooltipForDisbursement]: `Pencairan dana ke institusi pelajar sedang diproses`,
  [LoanStatusDashboard.tooltipAgreementActivated]: `Dana telah dicairkan ke institusi pelajar`,
  [LoanStatusDashboard.tooltipClosed]: `Pembayaran bulanan pelajar ke Danacita telah lunas`,
  [LoanStatusDashboard.tooltipCanceled]: `Aplikasi dibatalkan`,
  [LoanStatusDashboard.tooltipRejected]: `Aplikasi ditolak`,
}
