import { COUNTRY } from 'constants/config'

import danacita from './danacita.svg'
import bukas from './bukas.svg'

const getAppBrand = (country: Country) => {
  switch (country) {
    case 'PH':
      return bukas
    case 'ID':
      return danacita
  }
}

const appLogo = getAppBrand(COUNTRY)

export default appLogo
