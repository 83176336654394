import React, { useState, useEffect } from 'react'
import cx from 'classnames'

import AppIconSvg from 'assets/logos/appIconSvg'
import AppBrand from 'assets/logos/appBrand'

import styles from './AnimatedAppLogo.module.scss'

const AnimatedAppLogo = () => {
  const [expand, setExpand] = useState(false)
  const [slideLeft, setSlideLeft] = useState(false)
  const [slideUp, setSlideUp] = useState(false)

  useEffect(() => {
    const timeoutLogoExpand = setTimeout(() => {
      setExpand(true)
    }, 50)
    const timeoutLogoSlideLeft = setTimeout(() => {
      setSlideLeft(true)
    }, 400)
    const timeoutLogoSlideUp = setTimeout(() => {
      setSlideUp(true)
    }, 650)
    return () => {
      clearTimeout(timeoutLogoExpand)
      clearTimeout(timeoutLogoSlideLeft)
      clearTimeout(timeoutLogoSlideUp)
    }
  }, [])

  return (
    <div className={styles.animatedAppLogo}>
      <img
        className={cx(
          styles.logo,
          expand && styles.expand,
          slideLeft && styles.slideLeft
        )}
        src={AppIconSvg}
        alt="App Icon"
      />
      <img
        className={cx(styles.brand, slideUp && styles.slideUp)}
        src={AppBrand}
        alt="App Brand"
      />
    </div>
  )
}

export default AnimatedAppLogo
