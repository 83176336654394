import { Buttons, Links } from 'constants/copy/touchables'

export const BUTTONS_COPY: CopyRecord<Buttons> = {
  [Buttons.login]: 'Log in',
  [Buttons.register]: 'Finish registration',
  [Buttons.resetPassword]: 'Reset password',
  [Buttons.setPassword]: 'Set password',
  [Buttons.send]: 'Send',
  [Buttons.back]: 'Back',
  [Buttons.next]: 'Next',
  [Buttons.delete]: 'Remove',
  [Buttons.add]: 'Add',
  [Buttons.edit]: 'Edit',
  [Buttons.reportReceived]: 'Report Received',
  [Buttons.viewApplicant]: 'View applicant',
  [Buttons.sendNotification]: 'Send Notification',
  [Buttons.downloadReport]: 'Download Report',
  [Buttons.downloadAndSendNotification]: 'Download & Send Notification',
  [Buttons.viewAll]: 'View All',
  [Buttons.export]: 'Export',
  [Buttons.exportReport]: 'Export Report',
  [Buttons.exportAsPDF]: 'Export as PDF',
  [Buttons.save]: 'Save',
  [Buttons.editBasicInfo]: 'Edit Basic Information',
  [Buttons.remove]: 'Remove',
  [Buttons.cancel]: 'Cancel',
  [Buttons.preview]: 'Preview',
  [Buttons.submit]: 'Submit',
  [Buttons.addLead]: 'Add Lead',
  [Buttons.submitLead]: 'Submit Lead',
  [Buttons.viewDraft]: 'View Draft',
  [Buttons.saveDraft]: 'Save as Draft',
  [Buttons.exitAndSaveDraft]: 'Exit & save as draft',
  [Buttons.skip]: 'Skip',
  [Buttons.skipModal]: 'Skip',
  [Buttons.additionalInfo]: 'Additional Information',
  [Buttons.sendSMS]: 'Send SMS',
  [Buttons.addAddress]: 'Add Address',
  [Buttons.addContactNumber]: 'Add Contact Number',
  [Buttons.updatePopulation]: 'Update Population',
  [Buttons.addEmail]: 'Add Contact Person',
  [Buttons.addMainProgram]: 'Add Undergraduate Program/s',
  [Buttons.batchUpload]: 'Batch Upload',
  [Buttons.addGraduateProgram]: 'Add Graduate Program/s',
  [Buttons.addShortCourse]: 'Add Short Course/s',
  [Buttons.addFile]: 'Add File',
  [Buttons.otherFee]: 'Answer Survey',
  [Buttons.internalFinancingProgram]: 'Internal Financing Program',
  [Buttons.addDeadline]: 'Add Deadline',
  [Buttons.details]: 'Details',
  [Buttons.goBackToDashboard]: 'Go Back To Dashboard',
  [Buttons.answerSurvey]: 'Answer Survey',
  [Buttons.saveInternalFinancingProgram]: 'Save Internal Financing Program',
  [Buttons.savePaymentDeadline]: 'Save Deadline',
  [Buttons.VAupdate]: 'Virtual Account Update/s',
  [Buttons.scrollDown]: 'Scroll Down',
  [Buttons.applyFilters]: 'Apply Filters',
  [Buttons.reset]: 'Reset',
  [Buttons.downloadErrorFile]: 'Download Error File',
  [Buttons.EN]: 'EN',
  [Buttons.ID]: 'ID',
  [Buttons.integrationsBasic]: 'Basic',
  [Buttons.integrationsWholesale]: 'Wholesale',
  [Buttons.integrationsDocumentation]: 'Access Our Documentation',
  [Buttons.downloadAPI]: 'Download API Key',
  [Buttons.previewDocument]: 'Preview Document',
  [Buttons.downloadDocument]: 'Download Document',
  [Buttons.download]: 'Download',
  [Buttons.inviteNewAdmin]: 'Invite New Admin',
  [Buttons.discard]: 'Discard Changes',
  [Buttons.yes]: 'Yes',
}

export const LINKS_COPY: CopyRecord<Links> = {
  [Links.forgotPassword]: 'Forgot password?',
  [Links.getStarted]: 'Get started',
  [Links.edit]: 'Edit',
}
