import { COUNTRY } from 'constants/config'
import storage, { StorageKey } from 'utils/storage'
const selectedLanguage = String(storage.getItem(StorageKey.languageSelection))

const getAppName = () => {
  switch (COUNTRY) {
    case 'PH':
      return 'Bukas'
    case 'ID':
      return 'Danacita'
  }
}

const getCountryName = () => {
  switch (COUNTRY) {
    case 'PH':
      return 'Philippines'
    case 'ID':
      return 'Indonesia'
  }
}

const getLocale = () => {
  if (selectedLanguage === 'ID') {
    return 'id'
  }
  if (selectedLanguage === 'EN') {
    return 'en'
  }
  switch (COUNTRY) {
    case 'PH':
      return 'ph'
    case 'ID':
      return 'id'
  }
}

export const APP_NAME = getAppName()
export const COUNTRY_NAME = getCountryName()
export const LOCALE = getLocale()
