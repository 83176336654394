import * as ComponentCopies from './components'
import * as ContainerCopies from './containers'
import * as InputCopies from './inputs'
import * as PageCopies from './pages'
import * as TouchableCopies from './touchables'

export const Components = ComponentCopies
export const Containers = ContainerCopies
export const Inputs = InputCopies
export const Pages = PageCopies
export const Touchables = TouchableCopies
