import React, { Suspense } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import Sidebar from './Sidebar'
import Footer from './Footer'
import ViewProfile from './ViewProfile'
import Loader from './Loader'
import styles from './DashboardLayout.module.scss'

import { SidebarProvider } from 'hooks/useSidebar'

type Props = {
  children?: React.ReactNode
}

const DashboardLayout: React.FC<RouteComponentProps> = (
  props: RouteComponentProps & Props
) => {
  const { children } = props

  return (
    <SidebarProvider>
      <div className={styles.dashboardLayout}>
        <Sidebar className={styles.sidebar} />
        <div className={styles.content}>
          <main className={styles.main}>
            <Suspense fallback={<Loader />}>{children}</Suspense>
          </main>
          <Footer className={styles.footer} />
        </div>
        <ViewProfile />
      </div>
    </SidebarProvider>
  )
}

export default DashboardLayout
