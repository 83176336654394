import React from 'react'
import cx from 'classnames'

import 'jam-icons/css/jam.min.css'
import styles from './Icon.module.scss'

export type IconName =
  | 'alarm-clock'
  | 'alert'
  | 'alert-f'
  | 'arrow-left'
  | 'arrow-right'
  | 'arrow-square-up-right'
  | 'bell'
  | 'building'
  | 'building-f'
  | 'camera-f'
  | 'check'
  | 'circle-f'
  | 'clipboard'
  | 'close'
  | 'terminal'
  | 'cog-f'
  | 'cogs'
  | 'download'
  | 'envelope'
  | 'envelope-f'
  | 'ghost-org-square'
  | 'grid'
  | 'log-out'
  | 'menu'
  | 'phone'
  | 'phone-f'
  | 'pin-alt'
  | 'pin-alt-f'
  | 'plus'
  | 'plus-circle'
  | 'search'
  | 'signal'
  | 'smiley-f'
  | 'trash'
  | 'user'
  | 'users'
  | 'redo'
  | 'yahoo'
  | 'google'
  | 'paper-plane'
  | 'filter'
  | 'target'
  | 'chevron-left'
  | 'chevron-right'
  | 'chevron-up'
  | 'chevron-down'

type Props = {
  id?: string
  className?: string
  name: IconName
  onClick?: () => void
  size?: '3xs' | '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'
  type?:
    | 'black'
    | 'danger'
    | 'brown'
    | 'light'
    | 'lighter'
    | 'lightest'
    | 'primary'
    | 'primary-lighter'
    | 'success'
    | 'warning'
    | 'white'
}

const Icon = (props: Props) => {
  const { id, className, name, onClick, size, type } = props
  const cn = cx(
    `jam jam-${name}`,
    styles.icon,
    {
      [styles.button]: Boolean(onClick),
      [styles['3xs']]: size === '3xs',
      [styles['2xs']]: size === '2xs',
      [styles.xs]: size === 'xs',
      [styles.sm]: size === 'sm',
      [styles.md]: size === 'md',
      [styles.lg]: size === 'lg',
      [styles.xl]: size === 'xl',
      [styles['2xl']]: size === '2xl',
      [styles.light]: type === 'light',
      [styles.lighter]: type === 'lighter',
      [styles.lightest]: type === 'lightest',
      [styles.black]: type === 'black',
      [styles.white]: type === 'white',
      [styles.primary]: type === 'primary',
      [styles.primaryLighter]: type === 'primary-lighter',
      [styles.success]: type === 'success',
      [styles.danger]: type === 'danger',
      [styles.brown]: type === 'brown',
      [styles.warning]: type === 'warning',
    },
    className
  )
  if (onClick)
    return <span id={id} className={cn} role="button" onClick={onClick} />
  return <span id={id} className={cn} />
}

export default Icon
