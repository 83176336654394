import React, { useState, useCallback } from 'react'

import { AuthContext } from 'hooks/useAuth'
import useGTM from 'hooks/useGTM'
import { saveToken, deleteToken, getToken } from 'utils/auth'
import { Routes } from 'constants/routes'

import Pages from 'pages'
import DocumentHead from 'containers/DocumentHead'
import SessionTimeoutModal from 'containers/SessionTimeoutModal'
import session, { SessionStorageKey } from 'utils/sessionStorage'

const App = () => {
  useGTM()
  const [isAuth, setIsAuth] = useState(Boolean(getToken()))

  const login = useCallback((token: Token, next?: Routes) => {
    saveToken(token)
    session.removeItem(SessionStorageKey.sessionTimedOut)
    setIsAuth(true)
  }, [])

  const logout = useCallback(() => {
    deleteToken()
    setIsAuth(false)
  }, [])

  return (
    <AuthContext.Provider
      value={{
        isAuth,
        login,
        logout,
      }}
    >
      <DocumentHead />
      <Pages />
      <SessionTimeoutModal />
    </AuthContext.Provider>
  )
}

export default App
