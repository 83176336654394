import React from 'react'
import cx from 'classnames'
import { Modal as AntdModal } from 'antd'

import { COLORS } from 'constants/colors'
import Icon from 'components/Icon'
import { Heading } from 'components/Typography'
import styles from './Modal.module.scss'

type Props = {
  children?: React.ReactNode
  className?: string
  noHeader?: boolean
  onClose?: () => void
  withCloseIcon?: boolean
  title?: string
  visible?: boolean
  wrapClassName?: string
  rightSection?: React.ReactNode
  width?: number
}

const Modal = (props: Props) => {
  const {
    children,
    className,
    noHeader,
    onClose,
    title,
    visible,
    withCloseIcon = true,
    rightSection,
    width = 580,
  } = props

  if (rightSection) {
    return (
      <AntdModal
        visible={visible}
        closable={false}
        footer={null}
        width={width}
        onCancel={onClose}
        maskStyle={{ backgroundColor: COLORS['true-black-60'] }}
        className={cx(
          styles.modal,
          {
            [styles.noHeader]: noHeader,
          },
          className
        )}
      >
        <div
          style={{
            display: 'flex',
          }}
        >
          <div
            style={{
              width: '60%',
            }}
          >
            {title && (
              <div className={styles.header}>
                {withCloseIcon && (
                  <Icon
                    name="close"
                    size="lg"
                    type="primary"
                    className={styles.closeIcon}
                    onClick={onClose}
                  />
                )}

                <Heading noLeading level={2} size="md">
                  {title}
                </Heading>
              </div>
            )}

            {noHeader && onClose && (
              <Icon
                name="close"
                size="lg"
                className={styles.closeIcon}
                onClick={onClose}
              />
            )}
            <div className={styles.content}>{children}</div>
          </div>

          <div
            style={{
              width: '40%',
            }}
          >
            {rightSection}
          </div>
        </div>
      </AntdModal>
    )
  }

  return (
    <AntdModal
      visible={visible}
      closable={false}
      footer={null}
      width={580}
      onCancel={onClose}
      maskStyle={{ backgroundColor: COLORS['true-black-60'] }}
      className={cx(
        styles.modal,
        {
          [styles.noHeader]: noHeader,
        },
        className
      )}
    >
      {title && (
        <div className={styles.header}>
          {withCloseIcon && (
            <Icon
              name="close"
              size="lg"
              type="primary"
              className={styles.closeIcon}
              onClick={onClose}
            />
          )}

          <Heading noLeading level={2} size="md">
            {title}
          </Heading>
        </div>
      )}

      {noHeader && onClose && (
        <Icon
          name="close"
          size="lg"
          className={styles.closeIcon}
          onClick={onClose}
        />
      )}

      <div className={styles.content}>{children}</div>
    </AntdModal>
  )
}

export default Modal
