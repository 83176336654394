import { OTPForm } from 'constants/copy/containers'

export const OTP_FORM_COPY: CopyRecord<OTPForm> = {
  [OTPForm.heading]: 'Masukan OTP Anda',
  [OTPForm.subheading]:
    'Silahkan masukan OTP yang sudah terkirim ke email Anda yang terdaftar. Mohon untuk tidak memberikan kode OTP kepada siapapun',
  [OTPForm.countdown]: `Mohon tunggu <0>{{countdown}}</0> Mengirim ulang kode OTP.`,
  [OTPForm.receive]: 'Belum menerima kode OTP Anda?',
  [OTPForm.resend]: 'Kirim ulang kode OTP',
  [OTPForm.verify]: 'Verifikasi email Anda',
  [OTPForm.mail]: 'Mohon periksa inbox email anda',
  [OTPForm.alertInvalidOtp]: 'OTP salah, silahkan coba lagi.',
  [OTPForm.alertResentOtp]: 'Kirim ulang kode OTP berhasil',
}
