export const getMainItemAccessIntegrationVendor = (): SidebarMainItem[] => {
  return ['dashboard', 'applications', 'metrics', 'leads']
}

export const getMoreItemAccessIntegrationVendor = (): SidebarMoreItem[] => {
  return ['permissions', 'integrations', 'profile']
}

export const getMainItemAccess = (role: Role): SidebarMainItem[] => {
  switch (role) {
    case 'Risk Sharing Staff':
      return ['dashboard']
    case 'Leads Staff':
      return ['dashboard', 'leads']
    case 'Reports Staff':
      return ['dashboard', 'reports', 'metrics']
    case 'Applications Staff':
      return ['dashboard', 'applications']
    case 'Integrations Staff':
      return ['dashboard']
    case 'Guest':
      return ['dashboard', 'applications', 'reports', 'metrics', 'leads']
    case 'Finance Manager':
      return ['dashboard', 'applications', 'reports', 'metrics']
    case 'Risk Sharing Manager':
    case 'Leads Manager':
    case 'Reports Manager':
    case 'Applications Manager':
    case 'Integrations Manager':
    case 'System Admin':
    case 'Owner':
      return ['dashboard', 'applications', 'reports', 'metrics', 'leads']
    default:
      return ['dashboard']
  }
}

export const getMoreItemAccess = (role: Role): SidebarMoreItem[] => {
  switch (role) {
    case 'Risk Sharing Staff':
      return ['risk']
    case 'Integrations Staff':
      return ['integrations']
    case 'Leads Staff':
    case 'Reports Staff':
    case 'Applications Staff':
      return []
    case 'Guest':
      return ['profile']
    case 'Finance Manager':
      return ['risk']
    case 'Risk Sharing Manager':
      return ['profile', 'risk']
    case 'Integrations Manager':
      return ['profile', 'integrations']
    case 'Leads Manager':
    case 'Reports Manager':
    case 'Applications Manager':
    case 'System Admin':
      return ['profile']
    case 'Owner':
      return ['administrators', 'permissions', 'profile']
    default:
      return []
  }
}

export const isAllowViewApplications = (role: Role): boolean => {
  switch (role) {
    case 'Applications Staff':
    case 'Guest':
    case 'Finance Manager':
    case 'Risk Sharing Manager':
    case 'Integrations Manager':
    case 'Leads Manager':
    case 'Reports Manager':
    case 'Applications Manager':
    case 'System Admin':
    case 'Owner':
      return true
    default:
      return false
  }
}

export const isAllowViewReports = (role: Role): boolean => {
  switch (role) {
    case 'Reports Staff':
    case 'Guest':
    case 'Finance Manager':
    case 'Risk Sharing Manager':
    case 'Integrations Manager':
    case 'Leads Manager':
    case 'Reports Manager':
    case 'Applications Manager':
    case 'System Admin':
    case 'Owner':
      return true
    default:
      return false
  }
}

export const isAllowSendApplicationsNotification = (role: Role): boolean => {
  switch (role) {
    case 'Applications Staff':
    case 'Finance Manager':
    case 'Applications Manager':
    case 'System Admin':
    case 'Owner':
      return true
    default:
      return false
  }
}

export const isAllowDownloadReports = (role: Role): boolean => {
  switch (role) {
    case 'Reports Staff':
    case 'Finance Manager':
    case 'Reports Manager':
    case 'System Admin':
    case 'Applications Manager':
    case 'Applications Staff':
    case 'Owner':
      return true
    default:
      return false
  }
}

export const isAllowDownloadRiskReports = (role: Role): boolean => {
  switch (role) {
    case 'Risk Sharing Manager':
    case 'Risk Sharing Staff':
    case 'Finance Manager':
      return true
    default:
      return false
  }
}

export const isAllowCreateLeads = (role: Role): boolean => {
  switch (role) {
    case 'Leads Staff':
    case 'Leads Manager':
    case 'System Admin':
    case 'Owner':
      return true
    default:
      return false
  }
}

export const isAllowEditProfile = (role: Role): boolean => {
  switch (role) {
    case 'System Admin':
    case 'Owner':
      return true
    default:
      return false
  }
}

export const isAllowAccessLeadsErrorFile = (role: Role): boolean => {
  switch (role) {
    case 'Leads Staff':
    case 'Leads Manager':
    case 'System Admin':
    case 'Owner':
      return true
    default:
      return false
  }
}

export const isAllowToModifyCustomView = (role: Role | undefined) => {
  if (!role) return false

  switch (role) {
    case 'System Admin':
    case 'Reports Manager':
    case 'Leads Manager':
      return false

    default:
      return true
  }
}
