import React from 'react'

import Tooltip from 'components/Tooltip'

type TooltipContentProps = {
  active?: boolean
  children: React.ReactElement
  title: string
  type?: 'primary' | 'light'
}

const TooltipContent = (props: TooltipContentProps) => {
  const { active, children, title, type } = props
  if (active)
    return (
      <Tooltip placement="right" title={title} type={type}>
        {children}
      </Tooltip>
    )
  return children
}

export default TooltipContent
