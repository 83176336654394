import React, { createContext, useCallback, useContext, useState } from 'react'
import storage, { StorageKey } from 'utils/storage'

const defaultValue: {
  collapsed: boolean
  setCollapsed: (collapsed: boolean) => void
  toggleCollapsed: () => void
} = {
  collapsed: false,
  setCollapsed: () => undefined,
  toggleCollapsed: () => undefined,
}

export const SidebarContext = createContext(defaultValue)

type Props = {
  children?: React.ReactNode
}

export const SidebarProvider: React.FC<Props> = ({ children }: Props) => {
  const [collapsed, setCollapsed] = useState(
    storage.getItem(StorageKey.sidebar) === 'true'
  )

  const toggleCollapsed = useCallback(() => {
    const newCollapsed = !collapsed
    storage.setItem(StorageKey.sidebar, newCollapsed ? 'true' : 'false')
    setCollapsed(newCollapsed)
  }, [collapsed])

  return (
    <SidebarContext.Provider
      value={{ collapsed, setCollapsed, toggleCollapsed }}
    >
      {children}
    </SidebarContext.Provider>
  )
}

const useSidebar = () => useContext(SidebarContext)

export default useSidebar
