import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import { useLocation, Link } from 'react-router-dom'
import { Tag, Popover } from 'antd'
import dayjs from 'dayjs'

import useRequestState from 'hooks/useRequestState'
import useAuth from 'hooks/useAuth'
import useTranslation from 'hooks/useTranslation'
import { HomeQuery } from 'types/query'
import useQuery from 'hooks/useQuery'

import { useCurrentUser } from 'services/me'
import { usePartnersWithoutPagination } from 'services/schools'
import { logout } from 'services/auth'
import {
  logCloseFirstModalRecruitEnrollees,
  logCloseSecondModalRecruitEnrollees,
  logNotInterestedRecruitEnrollees,
  logInterestedRecruitEnrollees,
  logAnswerSurveyRecruitEnrollees,
} from 'services/analytics/events'
import {
  isRiskSharingModuleDisabled,
  isProfileSettingsModuleDisabled,
} from 'services/analytics/remoteConfigs'

import {
  getMainItemAccess,
  getMoreItemAccess,
  getMainItemAccessIntegrationVendor,
  getMoreItemAccessIntegrationVendor,
} from 'utils/roleAccess'
import storage, { StorageKey } from 'utils/storage'
import session, { SessionStorageKey } from 'utils/sessionStorage'
import { DashboardQuery } from 'types/query'
import { Routes } from 'constants/routes'
import COPY from 'constants/copy'

import Icon, { IconName } from 'components/Icon'
import Skeleton from 'components/Skeleton'
import { Heading, Text } from 'components/Typography'
import Modal from 'components/Modal'
import Button from 'components/Button/index'

import AnimatedAppLogo from 'containers/AnimatedAppLogo'

import appLogo from 'assets/logos/appLogo'
import appIcon from 'assets/logos/appIcon'
import styles from './Sidebar.module.scss'

import useSidebar from 'hooks/useSidebar'

import StudentFemaleWithBubbleChat from 'assets/illustrations/student_famale_with_bubble_chat.svg'
import PartnerLogoEmpty from 'assets/illustrations/partnerLogoEmpty.svg'

import RenderLinkedItem from './RenderLinkedItem'
import TooltipContent from './TooltipContent'

type Props = {
  className?: string
}

export type Item = {
  name: SidebarMainItem | SidebarMoreItem
  icon: IconName
  label: string
  path: Routes
}

type MenuItem = Item & {
  comingSoon?: boolean
  fakeDoor?: boolean
  newTag?: boolean
  highlight?: boolean
  subMenu?: Omit<Item, 'icon'>[]
}

const isPathActive = (path: string, curPath: string) => {
  if (path) return path.split('/')[1] === curPath.split('/')[1]
  return false
}

const Sidebar = (props: Props) => {
  const { className } = props
  const [refreshToken, setRefreshToken] = useState('')
  const [isLeadsFakeDoor, setIsLeadsFakeDoor] = useState(false)
  const [isLeadsSurvey, setIsLeadsSurvey] = useState(false)

  const { collapsed, toggleCollapsed } = useSidebar()

  const { [HomeQuery.welcome]: query } = useQuery()
  const hasSeenOnboardingModal = Boolean(
    storage.getItem(StorageKey.onboarding) && !query
  )
  const hasSeenHighlightLeadsMenu = Boolean(
    storage.getItem(StorageKey.leadsSidebarCoachmarking)
  )
  const useHighlight = false

  const [highlightLeadsMenu, setHighlightLeadsMenu] = useState<boolean>(false)
  const [showLeadsMenuCoachmarking, setShowLeadsMenuCoachmarking] =
    useState<boolean>(false)

  const [showSchoolListPopover, setShowSchoolListPopover] =
    useState<boolean>(false)

  const state = useRequestState()
  const { t, copy } = useTranslation()
  const { data: user } = useCurrentUser()
  const isPartnerManager = user?.type === 'Partner Manager'
  const isInetgrationVendor = user?.type === 'Integration Vendor'
  const { data: partners } = usePartnersWithoutPagination()
  const includeRisk = Boolean(
    partners?.filter((p) => p.is_risk_sharing_module_enabled).length
  )

  const MAIN_ITEMS: MenuItem[] = [
    {
      name: 'dashboard',
      icon: 'ghost-org-square',
      label: COPY.Components.Sidebar.dashboard,
      path: Routes.index,
    },
    {
      name: 'applications',
      icon: 'clipboard',
      label: COPY.Components.Sidebar.applications,
      path: Routes.applications,
    },
    {
      name: 'metrics',
      icon: 'signal',
      label: COPY.Components.Sidebar.reportsMetrics,
      path: Routes.metrics,
      subMenu: [
        {
          name: 'reports',
          label: COPY.Components.Sidebar.reports,
          path: Routes.reports,
        },
        {
          name: 'metrics',
          label: COPY.Components.Sidebar.metrics,
          path: Routes.metrics,
        },
      ],
    },
    {
      name: 'leads',
      icon: 'target',
      label: COPY.Components.Sidebar.leads,
      path: Routes.leadsWelcome,
    },
    {
      name: 'admissions',
      icon: 'grid',
      label: COPY.Components.Sidebar.admissions,
      path: Routes.admissions,
      newTag: true,
    },
  ]

  const MORE_ITEMS: MenuItem[] = [
    ...(includeRisk
      ? [
          {
            name: 'risk' as SidebarMoreItem,
            icon: 'grid' as IconName,
            label: COPY.Components.Sidebar.risk,
            path: Routes.risk,
            newTag: true,
          },
        ]
      : []),
    {
      name: 'permissions',
      icon: 'users',
      label: COPY.Components.Sidebar.permissions,
      path: Routes.permissions,
    },
    {
      name: 'profile',
      icon: 'cogs',
      label: COPY.Components.Sidebar.profileSettings,
      path: Routes.partnerProfile,
    },
    {
      name: 'integrations',
      icon: 'terminal',
      label: COPY.Components.Sidebar.integrations,
      path: Routes.integrations,
      subMenu: [
        {
          name: 'metrics',
          label: COPY.Components.Sidebar.lite,
          path: Routes.lite,
        },
        {
          name: 'reports',
          label: COPY.Components.Sidebar.apiDocs,
          path: Routes.integrations,
        },
        {
          name: 'metrics',
          label: COPY.Components.Sidebar.digestValidator,
          path: Routes.digestValidator,
        },
      ],
    },
  ]

  const isVARequestenabled = partners
    ? partners?.filter((school) => !school.is_virtual_account_request_enabled)
        .length === 0
    : false
  const location = useLocation()
  const { logout: clientLogout } = useAuth()

  let allowedMainItem: MenuItem[] = []
  let allowedMoreItem: MenuItem[] = []
  if (isPartnerManager) {
    allowedMainItem = MAIN_ITEMS
    allowedMoreItem = MORE_ITEMS
  } else {
    let mainItemAccess: SidebarMainItem[] = []
    let moreItemAccess: SidebarMoreItem[] = []
    if (user?.partner_role) {
      mainItemAccess = getMainItemAccess(user?.partner_role)
      moreItemAccess = getMoreItemAccess(user?.partner_role)
    }
    if (user?.type) {
      mainItemAccess = getMainItemAccessIntegrationVendor()
      moreItemAccess = getMoreItemAccessIntegrationVendor()
    }
    if (
      user?.type === 'Partner Operator' &&
      (user?.partner_role === 'Finance Manager' ||
        user?.partner_role === 'Risk Sharing Manager' ||
        user?.partner_role === 'Risk Sharing Staff')
    ) {
      mainItemAccess = ['dashboard', 'applications', 'metrics', 'leads']
      moreItemAccess = ['risk', 'permissions', 'integrations', 'profile']
    }

    for (let i = 0; i < MAIN_ITEMS.length; i++) {
      const checkMainItemAccess =
        mainItemAccess.filter((val) => val === MAIN_ITEMS[i].name).length === 1
      if (checkMainItemAccess) {
        allowedMainItem.push(MAIN_ITEMS[i])
      }
    }
    for (let i = 0; i < MORE_ITEMS.length; i++) {
      const checkMoreItemAccess =
        moreItemAccess.filter((val) => val === MORE_ITEMS[i].name).length === 1
      if (checkMoreItemAccess) {
        allowedMoreItem.push(MORE_ITEMS[i])
      }
    }
  }

  const isExistingUser = dayjs(user?.date_joined).isBefore('2021-06-01')

  const [disableRiskModule, setDisableRiskModule] = useState(true)
  const [disableProfileSettingsModule, setDisableProfileSettingsModule] =
    useState(true)
  const checkRemoteConfigRiskModule = async () => {
    setDisableRiskModule(await isRiskSharingModuleDisabled())
  }
  const checkRemoteConfigProfileSettingsModule = async () => {
    setDisableProfileSettingsModule(await isProfileSettingsModuleDisabled())
  }
  useEffect(() => {
    checkRemoteConfigRiskModule()
    checkRemoteConfigProfileSettingsModule()
  }, [])

  useEffect(() => {
    if (
      useHighlight &&
      isExistingUser &&
      hasSeenOnboardingModal &&
      !hasSeenHighlightLeadsMenu
    ) {
      setHighlightLeadsMenu(true)
      setShowLeadsMenuCoachmarking(true)
    }
  }, [
    useHighlight,
    isExistingUser,
    hasSeenOnboardingModal,
    hasSeenHighlightLeadsMenu,
  ])

  useEffect(() => {
    setRefreshToken(session.getItem(SessionStorageKey.refresh) as string)
  }, [refreshToken])

  const handleLogut = async () => {
    try {
      state.start()
      await logout({ refresh_token: refreshToken })
      clientLogout()
    } catch (e) {
      state.setError(e.response?.data?.detail)
      state.end()
    }
  }

  const logo = (
    <img
      className={styles.appLogo}
      src={collapsed ? appIcon : appLogo}
      alt="App logo"
    />
  )

  const animatedLogo = <AnimatedAppLogo />

  const closeCoachmarking = async () => {
    await setShowLeadsMenuCoachmarking(false)
    setHighlightLeadsMenu(false)
    storage.setItem(StorageKey.leadsSidebarCoachmarking, 'true')
  }

  const renderSchool = () => {
    const schools = partners
    const schoolIcons = []
    if (schools?.length) {
      const iteration = schools?.length > 3 ? 3 : schools?.length
      for (let i = 0; i < iteration; i++) {
        let schoolIcon
        if (schools[i].profile_photo_file) {
          schoolIcon = (
            <div className={styles.schoolIconContainer}>
              <img
                className={styles.schoolIcon}
                src={schools[i].profile_photo_file}
                alt="School profile"
              />
            </div>
          )
        } else {
          schoolIcon = (
            <div className={styles.emptySchoolIconContainer}>
              <Icon name="building" type="white" />
            </div>
          )
        }
        schoolIcons.push(schoolIcon)
      }

      const SchoolList = (
        <ul className={styles.schoolList}>
          {schools.map((school) => {
            return <li key={school.name}>{school.name}</li>
          })}
        </ul>
      )

      switch (schools.length) {
        case 1:
          return (
            <Popover
              visible={showSchoolListPopover}
              placement="rightTop"
              content={SchoolList}
            >
              <div className={styles.schoolIconGroup}>
                {schoolIcons[0]}
                <Text size="sm" className={styles.schoolIconText}>
                  {schools.length}{' '}
                  {t(copy.Components.Sidebar.schoolIconGroupText, {
                    plural: '',
                  })}
                </Text>
              </div>
            </Popover>
          )
        case 2:
          return (
            <Popover
              visible={showSchoolListPopover}
              placement="rightTop"
              content={SchoolList}
            >
              <div className={styles.schoolIconGroup}>
                <div className={styles.doubleIcon}>
                  <div className={styles.firstIcon}>{schoolIcons[0]}</div>
                  <div className={styles.secondIcon}>{schoolIcons[1]}</div>
                </div>
                <Text size="sm" className={styles.schoolIconText}>
                  {schools.length}{' '}
                  {t(copy.Components.Sidebar.schoolIconGroupText, {
                    plural: 'es',
                  })}
                </Text>
              </div>
            </Popover>
          )
        case 3:
          return (
            <Popover
              visible={showSchoolListPopover}
              placement="rightTop"
              content={SchoolList}
            >
              <div className={styles.schoolIconGroup}>
                <div className={styles.tripleIcon}>
                  <div className={styles.firstIcon}>{schoolIcons[0]}</div>
                  <div className={styles.secondIcon}>{schoolIcons[1]}</div>
                  <div className={styles.thirdIcon}>{schoolIcons[2]}</div>
                </div>
                <Text size="sm" className={styles.schoolIconText}>
                  {schools.length}{' '}
                  {t(copy.Components.Sidebar.schoolIconGroupText, {
                    plural: 'es',
                  })}
                </Text>
              </div>
            </Popover>
          )
        default:
          return (
            <Popover
              visible={showSchoolListPopover}
              placement="rightTop"
              content={SchoolList}
            >
              <div className={styles.schoolIconGroup}>
                <div className={styles.quadrupleIcon}>
                  <div className={styles.firstIcon}>{schoolIcons[0]}</div>
                  <div className={styles.secondIcon}>{schoolIcons[1]}</div>
                  <div className={styles.thirdIcon}>{schoolIcons[2]}</div>
                  <div className={styles.fourthIcon}>
                    <Text
                      size="sm"
                      type="white"
                      className={styles.fourthIconText}
                    >
                      +{schools.length - 3}
                    </Text>
                  </div>
                </div>
                <Text size="sm" className={styles.schoolIconText}>
                  {schools.length}{' '}
                  {t(copy.Components.Sidebar.schoolIconGroupText, {
                    plural: 'es',
                  })}
                </Text>
              </div>
            </Popover>
          )
      }
    } else {
      return null
    }
  }

  const darkLayerForLeadsMenuHighlight = (
    <div onClick={closeCoachmarking} className={styles.layer} />
  )

  return (
    <aside
      className={cx(
        styles.sidebar,
        collapsed && styles.sidebarCollapsed,
        className
      )}
    >
      <div
        className={styles.header}
        onClick={collapsed ? toggleCollapsed : undefined}
        role={collapsed ? 'button' : undefined}
      >
        {collapsed ? logo : <Link to={Routes.index}>{animatedLogo}</Link>}
        <Icon
          name="menu"
          size="lg"
          type="primary-lighter"
          className={styles.menuIcon}
          onClick={collapsed ? undefined : toggleCollapsed}
        />
      </div>

      <div className={styles.partner}>
        <Link
          to={
            location.pathname +
            (location.search
              ? location.search + `&${DashboardQuery.viewProfile}=1`
              : `?${DashboardQuery.viewProfile}=1`)
          }
          className={styles.logoContainer}
        >
          {user ? (
            <>
              <div className={styles.partnerLogo}>
                <img
                  src={PartnerLogoEmpty}
                  alt="Partner logo"
                  className={styles.partnerLogoEmpty}
                />
              </div>
              <div className={styles.textContainer}>
                <Text
                  block
                  ellipsize
                  size="lg"
                  strong
                  className={styles.nameText}
                >
                  {user?.first_name} {user?.last_name}
                </Text>
                <Text block ellipsize className={styles.roleText}>
                  {isPartnerManager ? user?.type : user?.partner_role}
                </Text>
              </div>
            </>
          ) : (
            <Skeleton
              className={styles.skeletonPartner}
              avatar={{ className: styles.skeletonPartnerLogo }}
            />
          )}
        </Link>
        <div
          className={styles.partnerInfo}
          onMouseEnter={() => setShowSchoolListPopover(true)}
          onMouseLeave={() => setShowSchoolListPopover(false)}
        >
          {partners ? (
            renderSchool()
          ) : (
            <Skeleton
              paragraph={{ rows: 1, className: styles.skeletonParagraph }}
            />
          )}
        </div>
      </div>

      <nav className={styles.menu}>
        <Text block uppercase size="xs" type="black" className={styles.label}>
          {t(copy.Components.Sidebar.main)}
        </Text>
        {allowedMainItem.map((item, idx) => {
          const active = isPathActive(item.path, location.pathname)
          const isSubMenuActive =
            item.subMenu?.some((menu) => location.pathname === menu.path) ||
            false

          if (
            item.name === 'admissions' &&
            partners &&
            partners.filter((school) => !school.is_admissions_module_enabled)
              .length > 0
          ) {
            return null
          }

          return (
            <div
              key={item.icon}
              className={cx(
                styles.itemContainer,
                active && !item.subMenu && styles.itemContainerActive,
                isSubMenuActive &&
                  item.subMenu &&
                  styles.itemContainerActiveHasSubMenu
              )}
            >
              <TooltipContent active={collapsed} title={t(item.label)}>
                {item.fakeDoor ? (
                  <div
                    className={cx(styles.item, active && styles.itemActive)}
                    onClick={() => setIsLeadsFakeDoor(!isLeadsFakeDoor)}
                  >
                    <Icon
                      name={item.icon}
                      size="md"
                      type={active ? 'primary-lighter' : undefined}
                      className={styles.itemIcon}
                    />
                    <Text
                      ellipsize
                      size="sm"
                      type={active ? 'primary-lighter' : undefined}
                      className={styles.itemLabel}
                    >
                      {t(item.label)}
                    </Text>
                    {!collapsed && (
                      <Tag color="processing">
                        {t(copy.Components.Sidebar.new)}
                      </Tag>
                    )}
                  </div>
                ) : (
                  <RenderLinkedItem
                    name={item.name}
                    path={item.path}
                    subMenu={item.subMenu}
                    active={item.subMenu ? isSubMenuActive : active}
                    icon={item.icon}
                    label={item.label}
                    newTag={item.newTag}
                    highlight={item.highlight}
                    showLeadsMenuCoachmarking={showLeadsMenuCoachmarking}
                    closeCoachmarking={closeCoachmarking}
                    isVARequestenabled={isVARequestenabled}
                  />
                )}
              </TooltipContent>
            </div>
          )
        })}

        {allowedMoreItem.length > 0 && (
          <Text block uppercase size="xs" type="black" className={styles.label}>
            {t(copy.Components.Sidebar.more)}
          </Text>
        )}
        {allowedMoreItem.map((item, idx) => {
          const isSubMenuActive =
            item.subMenu?.some((menu) => location.pathname === menu.path) ||
            false
          const active = isPathActive(item.path, location.pathname)
          let type: 'lighter' | 'primary-lighter' | undefined = undefined
          if (item.comingSoon) type = 'lighter'
          else if (active) type = 'primary-lighter'
          const content = (
            <>
              {item.name === 'integrations' ? (
                <Text size="lg" type={type} className={styles.itemIcon}>
                  {'</>'}
                </Text>
              ) : (
                <Icon
                  name={item.icon}
                  size="md"
                  type={type}
                  className={styles.itemIcon}
                />
              )}

              <Text
                ellipsize
                size="sm"
                type={type}
                className={styles.itemLabel}
              >
                {t(item.label)}
              </Text>
              {!collapsed && item.newTag && (
                <Tag>{t(copy.Components.Sidebar.new)}</Tag>
              )}
            </>
          )

          return (
            <div
              key={item.name}
              className={cx(
                styles.itemContainer,
                active && styles.itemContainerActive
              )}
            >
              <TooltipContent
                active={collapsed || item.comingSoon}
                title={
                  item.comingSoon
                    ? t(copy.Components.Sidebar.comingSoon)
                    : t(item.label)
                }
                type={item.comingSoon ? 'light' : undefined}
              >
                {item.comingSoon ? (
                  <div className={cx(styles.item, active && styles.itemActive)}>
                    {content}
                  </div>
                ) : (
                  <RenderLinkedItem
                    name={item.name}
                    path={item.path}
                    subMenu={item.subMenu}
                    active={item.subMenu ? isSubMenuActive : active}
                    icon={item.icon}
                    label={item.label}
                    newTag={item.newTag}
                    highlight={item.highlight}
                    showLeadsMenuCoachmarking={showLeadsMenuCoachmarking}
                    closeCoachmarking={closeCoachmarking}
                    isVARequestenabled={isVARequestenabled}
                  />
                )}
              </TooltipContent>
            </div>
          )
        })}
      </nav>

      <div className={styles.footer}>
        <TooltipContent active={collapsed} title="Logout">
          <div
            className={styles.footerContent}
            role="button"
            onClick={handleLogut}
          >
            <Icon name="log-out" size="md" className={styles.logoutIcon} />
            <Text ellipsize size="xs" className={styles.logout}>
              {t(copy.Components.Sidebar.logout)}
            </Text>
          </div>
        </TooltipContent>
      </div>

      <Modal
        visible={isLeadsFakeDoor}
        className={styles.modalFakeDoor}
        noHeader={true}
        onClose={() => {
          logCloseFirstModalRecruitEnrollees()
          setIsLeadsFakeDoor(false)
        }}
      >
        <img src={StudentFemaleWithBubbleChat} alt="Female Student" />
        <Heading level={1}>
          {t(copy.Components.Sidebar.recruitEnrollees)}
        </Heading>
        <Text paragraph>
          {t(copy.Components.Sidebar.recruitEnrolleesModalBody)}
        </Text>
        <Button
          type="light"
          onClick={() => {
            logNotInterestedRecruitEnrollees()
            setIsLeadsFakeDoor(false)
          }}
        >
          {t(copy.Components.Sidebar.recruitEnrolleesNotInterested)}
        </Button>
        <Button
          onClick={() => {
            logInterestedRecruitEnrollees()
            setIsLeadsFakeDoor(false)
            setIsLeadsSurvey(true)
          }}
        >
          {t(copy.Components.Sidebar.recruitEnrolleesInterested)}
        </Button>
      </Modal>

      <Modal
        visible={isLeadsSurvey}
        className={styles.modalFakeDoor}
        noHeader={true}
        onClose={() => {
          logCloseSecondModalRecruitEnrollees()
          setIsLeadsSurvey(false)
        }}
      >
        <img src={StudentFemaleWithBubbleChat} alt="Female Student" />
        <Heading level={1}>
          {t(copy.Components.Sidebar.recruitEnrollees)}
        </Heading>
        <Text paragraph>
          {t(copy.Components.Sidebar.recruitEnrolleesModalBodySurvey)}
        </Text>
        <Button onClick={() => logAnswerSurveyRecruitEnrollees}>
          <a
            href="https://erudifi.typeform.com/to/vzEy41Ly"
            target={'_blank'}
            rel="noreferrer"
          >
            {t(copy.Components.Sidebar.recruitEnrolleesAnswer)}
          </a>
        </Button>
      </Modal>

      {highlightLeadsMenu && darkLayerForLeadsMenuHighlight}
    </aside>
  )
}

export default Sidebar
