import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import { LOCALE } from 'constants/countryInfo'
import { COPY_RESOURCES } from 'constants/copy'
import { IS_ID, IS_PH } from 'constants/config'

const resources = {
  en: { translation: COPY_RESOURCES.en },
  ...(IS_PH && { ph: { translation: COPY_RESOURCES.ph } }),
  ...(IS_ID && { id: { translation: COPY_RESOURCES.id } }),
}

export const initializeI18n = () =>
  i18next.use(initReactI18next).init({
    resources,
    lng: LOCALE,
    fallbackLng: 'en',
  })

export const changeI18n = (id?: boolean) =>
  i18next.use(initReactI18next).init({
    resources,
    lng: id ? 'id' : 'en',
    fallbackLng: 'en',
  })
