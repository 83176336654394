import useRequest, { Config } from './swr'
import request from 'services/request'

type PartnersResponse = {
  count: number
  next: string
  previous: string
  results: School[]
}

export const useSchool = (config?: Config<School>) =>
  useRequest<School>({ method: 'GET', url: `/partner/school/` }, config)

export const updateSchool = (data: Partial<School>) => {
  const url = `/partner/${data.id}/school/`
  return request.patch<School>(url, data)
}

export const usePartners = (config?: Config<PartnersResponse>) =>
  useRequest<PartnersResponse>(
    {
      method: 'GET',
      url: '/partner/schools/',
    },
    config
  )

export const usePartnersWithoutPagination = (config?: Config<School[]>) =>
  useRequest<School[]>(
    {
      method: 'GET',
      url: `/partner/schools/?disable_pagination=1`,
    },
    config
  )

export const usePartnerCourses = (
  params: { schoolId?: number },
  config?: Config<PartnerCourse[]>
) =>
  useRequest<PartnerCourse[]>(
    params.schoolId
      ? {
          method: 'GET',
          url: `/partner/${params.schoolId}/courses/`,
          params: {},
        }
      : null,
    config
  )

export const useAllPartnerCourses = (
  params?: null,
  config?: Config<PartnerCourse[]>
) => {
  return useRequest<PartnerCourse[]>(
    {
      method: 'GET',
      url: '/partner/courses/',
    },
    config
  )
}

export const usePartnerBanks = (config?: Config<PartnerBank[]>) =>
  useRequest<PartnerBank[]>({ method: 'GET', url: `/partners/banks/` }, config)
