import { Labels, Errors, Messages } from 'constants/copy/inputs'

export const INPUTS_COPY: CopyRecord<Labels> = {
  [Labels.firstName]: 'First Name',
  [Labels.lastName]: 'Last Name',
  [Labels.role]: 'Role',
  [Labels.campus]: 'Campus',
  [Labels.allSchool]: `All Schools`,
  [Labels.disbursementNotification]: 'Disbursement Notification',
  [Labels.POCLabel]: 'Should receive disbursement notification via email',
  [Labels.access]: 'Access',
  [Labels.email]: 'Email',
  [Labels.password]: 'Password',
  [Labels.label]: 'Label',
  [Labels.type]: 'Type',
  [Labels.contactNumber]: 'Contact number',
  [Labels.partnerName]: 'Partner’s name',
  [Labels.province]: 'Province',
  [Labels.city]: 'City or town',
  [Labels.streetAddress]: 'Street Address (Unit / Street / Barangay)',
}

export const INPUT_ERRORS_COPY: CopyRecord<Errors> = {
  [Errors.required]: 'This field is required',
  [Errors.requestThrottle]:
    'You have exceeded the maximum number of attempts. Please try again later.',
  [Errors.invalidCredentials]: 'Invalid email or password',
  [Errors.multipleExistingUser]:
    'User/s [{{usernames}}] already exist/s. Deselect them to proceed.',
  [Errors.wrongEmailFormat]: 'Enter a valid email address',
  [Errors.duplicateContactNumber]: 'Mobile number already exists',
  [Errors.duplicateContactEmail]: 'Email already exists',
  [Errors.multipleExistingEmail]: 'Email [{{emails}}] already exists',
  [Errors.multipleExistingEmailInPostDraft]:
    'Email/s [{{emails}}] already exist/s. Deselect them to proceed.',
  [Errors.invalidURL]: 'Please provide a valid url',
  [Errors.phoneNumberTooShort]: 'Mobile number must be 10 characters.',
}

export const INPUT_MESSAGES_COPY: CopyRecord<Messages> = {
  [Messages.selectDate]: 'Select Dates',
  [Messages.startDate]: 'Start Date',
  [Messages.endDate]: 'End Date',
}
