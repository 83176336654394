import React from 'react'
import cx from 'classnames'

import { APP_NAME } from 'constants/countryInfo'
import { Text } from 'components/Typography'
import styles from './Footer.module.scss'

type Props = {
  className?: string
}

const Footer = (props: Props) => {
  const { className } = props
  return (
    <footer id="footer" className={cx(styles.footer, className)}>
      <Text block centered noLeading size="xs" type="light">
        Powered by {APP_NAME}
      </Text>
      <Text
        block
        centered
        noLeading
        size="xs"
        type="light"
        className={styles.copyright}
      >
        {new Date().getFullYear()} All rights reserved
      </Text>
    </footer>
  )
}

export default Footer
