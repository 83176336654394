import { OTPForm } from 'constants/copy/containers'

export const OTP_FORM_COPY: CopyRecord<OTPForm> = {
  [OTPForm.heading]: 'Enter your OTP',
  [OTPForm.subheading]:
    'Please enter the One-Time Password sent to your registered email. Never share your OTP with anyone.',
  [OTPForm.countdown]: `Please wait <0>{{countdown}}</0> to resend the code.`,
  [OTPForm.receive]: 'Did not receive your OTP?',
  [OTPForm.resend]: 'Resend OTP',
  [OTPForm.verify]: 'Verify your email',
  [OTPForm.mail]: 'Access your email',
  [OTPForm.alertInvalidOtp]: 'Invalid OTP, please try again.',
  [OTPForm.alertResentOtp]: 'Successfully sent OTP',
}
