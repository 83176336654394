import { useEffect } from 'react'

import { IS_ENABLE_GTM, GTM_ID } from 'constants/config'

const useGTM = () => {
  useEffect(() => {
    if (IS_ENABLE_GTM) {
      const script = document.createElement('script')
      script.append(`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${GTM_ID}');
        `)
      document.head.prepend(script)

      const noscript = document.createElement('noscript')
      const iframe = document.createElement('iframe')
      iframe.setAttribute(
        'src',
        `https://www.googletagmanager.com/ns.html?id=${GTM_ID}`
      )
      iframe.setAttribute('height', '0')
      iframe.setAttribute('width', '0')
      iframe.setAttribute('style', 'display:none;visibility:hidden')
      noscript.append(iframe)
      document.body.prepend(noscript)
    }
  }, [])
}

export default useGTM
