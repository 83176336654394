export enum StorageKey {
  onboarding = 'onboarding',
  administratorOnboarding = 'administratorOnboarding',
  sidebar = 'sidebar',
  leadsSidebarCoachmarking = 'leadsSidebarCoachmarking',
  leadsPageCoachmarkings = 'leadsPageCoachmarkings',
  leadsCreationCoachmarkings = 'leadsCreationCoachmarkings',
  leadsCreateSectionHighlight = 'leadsCreateSectionHighlight',
  riskCoachmarking = 'riskCoachmarking',
  languageSelection = 'languageSelection',
  integrationsCoachmarkings = 'integrationsCoachmarkings',
  permissionsOnboarding = 'permissionsOnboarding',
}

const getItem = (key: StorageKey) => {
  return localStorage.getItem(StorageKey[key])
}

const setItem = (key: StorageKey, value: string) => {
  return localStorage.setItem(StorageKey[key], value)
}

const removeItem = (key: StorageKey) => {
  return localStorage.removeItem(key)
}

const removeAllItems = () => {
  return localStorage.clear()
}

const storage = {
  getItem,
  setItem,
  removeItem,
  removeAllItems,
}

export default storage
