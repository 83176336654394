/*
    TAKE NOTE:

    PUT ALL YOUR process.env here!

    ENV key should be prefixed with `REACT_APP`
    and default value should be prefixed with `PLACEHOLDER_REACT_APP`

    PLEASE update:
    - Configmap(For staging, beta, production),
    - .env(For local environment) and
    - docker-compose.yml(For local environment running docker)
 */

export const REACT_APP_API_URL =
  process.env.REACT_APP_API_URL || 'PLACEHOLDER_REACT_APP_API_URL'
export const REACT_APP_API_WAGTAIL_URL =
  process.env.REACT_APP_API_WAGTAIL_URL ||
  'PLACEHOLDER_REACT_APP_API_WAGTAIL_URL'
export const REACT_APP_TESTAPIKEY_URL =
  process.env.REACT_APP_TESTAPIKEY_URL || 'PLACEHOLDER_REACT_APP_TESTAPIKEY_URL'
export const REACT_APP_BASIC_DOCUMENTATION_URL =
  process.env.REACT_APP_BASIC_DOCUMENTATION_URL || 'PLACEHOLDER_REACT_APP_BASIC_DOCUMENTATION_URL'
export const REACT_APP_WHOLESALE_DOCUMENTATION_URL =
  process.env.REACT_APP_WHOLESALE_DOCUMENTATION_URL || 'PLACEHOLDER_REACT_APP_WHOLESALE_DOCUMENTATION_URL'
export const REACT_APP_APIKEY_PDF_DOCUMENTATION_URL =
  process.env.REACT_APP_APIKEY_PDF_DOCUMENTATION_URL ||
  'PLACEHOLDER_REACT_APP_APIKEY_PDF_DOCUMENTATION_URL'
export const REACT_APP_COUNTRY =
  process.env.REACT_APP_COUNTRY || 'PLACEHOLDER_REACT_APP_COUNTRY'
export const REACT_APP_FIREBASE_API_KEY =
  process.env.REACT_APP_FIREBASE_API_KEY ||
  'PLACEHOLDER_REACT_APP_FIREBASE_API_KEY'
export const REACT_APP_FIREBASE_AUTH_DOMAIN =
  process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ||
  'PLACEHOLDER_REACT_APP_FIREBASE_AUTH_DOMAIN'
export const REACT_APP_FIREBASE_PROJECT_ID =
  process.env.REACT_APP_FIREBASE_PROJECT_ID ||
  'PLACEHOLDER_REACT_APP_FIREBASE_PROJECT_ID'
export const REACT_APP_FIREBASE_STORAGE_BUCKET =
  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ||
  'PLACEHOLDER_REACT_APP_FIREBASE_STORAGE_BUCKET'
export const REACT_APP_FIREBASE_MESSAGING_SENDER_ID =
  process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID ||
  'PLACEHOLDER_REACT_APP_FIREBASE_MESSAGING_SENDER_ID'
export const REACT_APP_FIREBASE_APP_ID =
  process.env.REACT_APP_FIREBASE_APP_ID ||
  'PLACEHOLDER_REACT_APP_FIREBASE_APP_ID'
export const REACT_APP_FIREBASE_MEASUREMENT_ID =
  process.env.REACT_APP_FIREBASE_MEASUREMENT_ID ||
  'PLACEHOLDER_REACT_APP_FIREBASE_MEASUREMENT_ID'
export const REACT_APP_ENABLE_GTM =
  process.env.REACT_APP_ENABLE_GTM || 'PLACEHOLDER_REACT_APP_ENABLE_GTM'
export const REACT_APP_GTM_ID =
  process.env.REACT_APP_GTM_ID || 'PLACEHOLDER_REACT_APP_GTM_ID'
export const REACT_APP_GOOGLE_ANALYTICS_GTAG =
  process.env.REACT_APP_GOOGLE_ANALYTICS_GTAG ||
  'PLACEHOLDER_REACT_APP_GOOGLE_ANALYTICS_GTAG'
export const REACT_APP_PERMISSIONS_PAGE =
  process.env.REACT_APP_PERMISSIONS_PAGE ||
  'PLACEHOLDER_REACT_APP_PERMISSIONS_PAGE'
export const REACT_APP_MAXIMUM_PARTNER_USER =
  process.env.REACT_APP_MAXIMUM_PARTNER_USER ||
  'PLACEHOLDER_REACT_APP_MAXIMUM_PARTNER_USER'
export const NODE_ENV = process.env.NODE_ENV
