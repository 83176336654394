import {
  REACT_APP_API_URL,
  REACT_APP_COUNTRY,
  REACT_APP_API_WAGTAIL_URL,
  REACT_APP_ENABLE_GTM,
  REACT_APP_GTM_ID,
  REACT_APP_GOOGLE_ANALYTICS_GTAG,
} from '../env'

export const IS_ENABLE_GTM = REACT_APP_ENABLE_GTM === 'true'

export const API_URL = REACT_APP_API_URL
export const API_WAGTAIL_URL = REACT_APP_API_WAGTAIL_URL
export const COUNTRY = REACT_APP_COUNTRY
export const GTM_ID = REACT_APP_GTM_ID
export const GOOGLE_ANALYTICS_GTAG = REACT_APP_GOOGLE_ANALYTICS_GTAG

export const IS_PROD: boolean =
  API_URL === 'https://api.bukas.ph' || API_URL === 'https://api.danacita.co.id'
export const IS_SANDBOX: boolean =
  API_URL === 'https://api.sandbox.bukas.ph' ||
  API_URL === 'https://api.sandbox.danacita.co.id'
export const IS_DEV: boolean =
  API_URL === 'https://api.dev.bukas.ph' ||
  API_URL === 'https://api.dev.danacita.co.id'
export const IS_BETA: boolean =
  API_URL === 'https://api.beta.bukas.ph' ||
  API_URL === 'https://api.beta.danacita.co.id'

if (!API_URL) {
  throw new Error('Environment variable "REACT_APP_API_URL" not found.')
}

if (!API_WAGTAIL_URL) {
  throw new Error(
    'Environment variable "REACT_APP_API_WAGTAIL_URL " not found.'
  )
}

if (!COUNTRY) {
  throw new Error('Environment variable "REACT_APP_COUNTRY" not found.')
}

export const IS_PH = COUNTRY === 'PH'
export const IS_ID = COUNTRY === 'ID'

export const LITE_URL =
  (COUNTRY === 'PH' && IS_PROD && 'https://pay.bukas.ph/lite/') ||
  (COUNTRY === 'PH' && IS_DEV && 'https://pay.dev.bukas.ph/lite/') ||
  (COUNTRY === 'PH' && IS_SANDBOX && 'https://pay.sandbox.bukas.ph/lite/') ||
  (COUNTRY === 'PH' && IS_BETA && 'https://pay.beta.bukas.ph/lite/') ||
  (COUNTRY === 'ID' && IS_PROD && 'https://pay.danacita.co.id/lite/') ||
  (COUNTRY === 'ID' && IS_DEV && 'https://pay.dev.danacita.co.id/lite/') ||
  (COUNTRY === 'ID' &&
    IS_SANDBOX &&
    'https://pay.sandbox.danacita.co.id/lite/') ||
  (COUNTRY === 'ID' && IS_BETA && 'https://pay.beta.danacita.co.id/lite/')
