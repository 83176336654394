import { COUNTRY } from 'constants/config'

import bukasLogo from './bukas.png'
import danacitaLogo from './danacita.png'

const getAppLogo = (country: Country) => {
  switch (country) {
    case 'PH':
      return bukasLogo
    case 'ID':
      return danacitaLogo
  }
}

const appLogo = getAppLogo(COUNTRY)

export default appLogo
